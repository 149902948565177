import React, { useEffect, useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { useHistory } from "react-router";

import infoHead from "../../icons/infoHead.svg";
import infoDualHead from "../../icons/infoDualHead.svg";
import borrow from "../../icons/borrow.svg";
const getLastRouteName = (route: string) => {
  const splitRoutes = route.split("/");
  return splitRoutes.slice(-1)[0];
};

export const Menu = () => {
  const history = useHistory();

  useEffect(() => {
    const match = window.location.pathname;
    const lastPath = getLastRouteName(match);
    console.log("last route is", lastPath);
    setMenuOption(lastPath);
  });
  const [menuOption, setMenuOption] = useState<string>("");

  return (
    <div className="createAccountMenu">
      <div className="createAccountMenuContent">
        <ButtonGroup vertical defaultValue="detail">
          <Button
            className={
              menuOption === "detail"
                ? "m-1 menubuttonSelected"
                : "m-1 menubuttonNormal"
            }
            value="detail"
            onClick={() => {
              history.push("/createaccount/detail");
              setMenuOption("detail");
            }}
          >
            <img
              src={infoHead}
              style={{ marginRight: 14 }}
              alt={"no infoHead"}
            />
            Account Details
          </Button>
          <Button
            className={
              menuOption === "borrowcapacity"
                ? "m-1 menubuttonSelected"
                : "m-1 menubuttonNormal"
            }
            value="borrowcapacity"
            onClick={() => {
              history.push("/createaccount/borrowcapacity");
              setMenuOption("borrowcapacity");
            }}
          >
            <img src={borrow} style={{ marginRight: 14 }} alt={"no borrow"} />
            Borrowing Capacity
          </Button>
          <Button
            className={
              menuOption === "codeveloper"
                ? "m-1 menubuttonSelected"
                : "m-1 menubuttonNormal"
            }
            value="codeveloper"
            onClick={() => {
              history.push("/createaccount/codeveloper");
            }}
          >
            <img
              src={infoDualHead}
              style={{ marginRight: 14, color: "red" }}
              alt={"no info dual head"}
            />
            Co-Developer
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
