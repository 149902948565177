import { useState } from "react";
import checkInput from "../utils/checkInput";
import _ from "lodash";

const initialState = {
  email: { value: "", errMessage: "" },
  password: { value: "", errMessage: "" },
  reEnter: { value: "", errMessage: "" },
};

type CreateAccountKey = "email" | "password" | "reEnter";

type CreateAccount = Record<
  CreateAccountKey,
  { value: string; errMessage?: string }
>;
type setFunction = (CreateAccountKey, string) => void;

const validateInput = (newValue, key, setCreateAccount) => {
  if (key === "email" && !checkInput(newValue[key].value, "email")) {
    newValue = {
      ...newValue,
      [key]: { value: newValue[key].value, errMessage: "Invalid email format" },
    };
  }
  if (key === "password" && !checkInput(newValue[key].value, "password")) {
    newValue = {
      ...newValue,
      [key]: { value: newValue[key].value, errMessage: "Weak password" },
    };
  }

  if (key === "reEnter" && newValue[key].value !== newValue["password"].value) {
    newValue = {
      ...newValue,
      [key]: {
        value: newValue[key].value,
        errMessage: "Re-entered wrong password",
      },
    };
  }

  setCreateAccount(newValue);
};

const debouceVerification = _.debounce(
  (account, key, setCreateAccount) =>
    validateInput(account, key, setCreateAccount),
  800
);

export const useCreateAccount = (): [CreateAccount, boolean, setFunction] => {
  const [createAccount, setCreateAccount] = useState(initialState);
  const [newForm, setNewForm] = useState(true);

  const setValue = (key, value) => {
    setNewForm(false);
    let newCreateAccount = { ...createAccount, [key]: { value } };

    //We have to define the deboucefn outside this setValue,
    //otherwise, every time setValue called, it will create another debouceVerification instance, which would have no effect to the previous debounce fn

    debouceVerification(newCreateAccount, key, setCreateAccount);

    setCreateAccount(newCreateAccount);
  };

  return [createAccount, newForm, setValue];
};
