import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";

import { Accordion, Card, Button } from "react-bootstrap";

import emailLogo from "../../icons/email.svg";
import passwordLogo from "../../icons/password.svg";

export const Detail = ({ createAccount, newForm, setValue }) => {
  return (
    <div className="createAccountMain">
      <div className="createAccountMainHeader">
        <p className="stepHeader">Step One</p>
        <p className="titleHeader">Account Details</p>
      </div>
      <div className="createAccountMainContent">
        <Accordion>
          <Card className="createAccountCard">
            <Card.Header className="createAccountCardHeader">
              Create an account
            </Card.Header>
            <Card.Body className="createAccountCardBodyColumn">
              <text className="formIntro">
                Sign up with your email address so that your information is not
                lost and is safe with us.
              </text>
              <InputBase
                id="email"
                placeholder="Enter your email"
                className="form-input"
                value={createAccount.email.value}
                onChange={(evt) => {
                  setValue("email", evt.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={emailLogo} alt={"no email logo"} />
                  </InputAdornment>
                }
              />

              {createAccount.email.errMessage && (
                <a className="validatingHintCreate">
                  {createAccount.email.errMessage}
                </a>
              )}
              <InputBase
                id="password"
                placeholder="Enter your password"
                className="form-input"
                type="password"
                value={createAccount.password.value}
                onChange={(evt) => {
                  setValue("password", evt.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={passwordLogo} alt={"no password logo"} />
                  </InputAdornment>
                }
              />
              {createAccount.password.errMessage && (
                <a className="validatingHintCreate">
                  {createAccount.password.errMessage}
                </a>
              )}
              <InputBase
                id="password"
                placeholder="Confirm your password"
                className="form-input"
                type="password"
                value={createAccount.reEnter.value}
                onChange={(evt) => {
                  setValue("reEnter", evt.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={passwordLogo} alt={"no password logo"} />
                  </InputAdornment>
                }
              />
              {createAccount.reEnter.errMessage && (
                <a className="validatingHintCreate">
                  {createAccount.reEnter.errMessage}
                </a>
              )}
            </Card.Body>
            <Card.Footer className="createAccountCardFooter">
              <Button
                className="formButton"
                style={{ width: "100%", color: "white" }}
              >
                Next
              </Button>
            </Card.Footer>
          </Card>
        </Accordion>
      </div>
    </div>
  );
};
