import { useState } from "react";
import Button from "@material-ui/core/Button";
import { InputBase } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import passwordLogo from "../../icons/password.svg";
import checkInput from "../../utils/checkInput";

const ResetPassword = () => {
  const [password, setPassword] = useState<string>("");
  const [passwordValid, setPasswordValid] = useState<Boolean>(true);
  const [passwordChecking, setPasswordChecking] = useState<Boolean>(false);
  return (
    <div className="logInBox">
      <h1 className="headerText">Please reset your password</h1>
      <a className="subtitle">
        Enter a new password and confirm below to complete your password
        recovery.
      </a>
      <form className="logInForm">
        <InputBase
          id="email"
          placeholder="Enter your new password"
          className="form-input"
          value={password}
          onChange={(evt) => {
            setPassword(evt.target.value);
            if (passwordChecking) {
              setPasswordValid(checkInput(evt.target.value, "password"));
            }
          }}
          onBlur={(evt) => {
            setPasswordChecking(!checkInput(evt.target.value, "password"));
            setPasswordValid(checkInput(evt.target.value, "password"));
          }}
          startAdornment={
            <InputAdornment position="start">
              <img src={passwordLogo} alt={"no password logo"} />
            </InputAdornment>
          }
        />
        {!passwordValid && (
          <a className="validatingHint">Password is not strong enough</a>
        )}

        <Button className="form-button">Reset Password</Button>
      </form>
    </div>
  );
};

export default ResetPassword;
