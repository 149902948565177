import React from "react";
import "../css/logInPage.scss";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ForgetPassword from "../components/login/ForgetPassword";

import ResetPassword from "../components/login/ResetPassword";
import Sent from "../components/login/Sent";
import StatusCheck from "../components/login/StatusCheck";
import dualBall from "../icons/dualBall.svg";
import LoginBox from "../components/login/LogInBox";

const LoginPage = () => {
  const match = useRouteMatch();
  return (
    <div className="containerLogin">
      <img src={dualBall} style={{ margin: "20px" }}></img>

      <Switch>
        <Route path={`${match.path}/forgetpassword`}>
          <ForgetPassword />
          {forgetPasswordHint(false, match.path)}
        </Route>
        <Route path={`${match.path}/statuscheck`}>
          <StatusCheck />
          {forgetPasswordHint(false, match.path)}
        </Route>
        <Route path={`${match.path}/sent`}>
          <Sent />
          {forgetPasswordHint(false, match.path)}
        </Route>
        <Route path={`${match.path}/resetpassword`}>
          <ResetPassword />
          {forgetPasswordHint(false, match.path)}
        </Route>
        <Route path={match.path}>
          <LoginBox />
          {forgetPasswordHint(true, match.path)}
        </Route>
      </Switch>
    </div>
  );
};

const forgetPasswordHint = (forget: Boolean, path: string) => {
  if (forget) {
    return (
      <text className="hinttext">
        Forgot your password?{" "}
        <a href={`${path}/forgetpassword`}>Reset password</a>
      </text>
    );
  }
  return (
    <text className="hinttext">
      Already have an account? <a href={path}>Login now</a>
    </text>
  );
};

export default LoginPage;
