export const searchBoxControl = (
  map,
  searchBox,
  setSearchOpen,
  setInputAddress
) => {
  const places = searchBox.getPlaces();

  if (places.length == 0) {
    return;
  }

  // For each place, get the icon, name and location.
  const bounds = new google.maps.LatLngBounds();
  places.forEach((place) => {
    if (!place.geometry || !place.geometry.location) {
      console.log("Returned place contains no geometry");
      return;
    }

    // Create a marker for each place.

    if (place.geometry.viewport) {
      // Only geocodes have viewport.
      bounds.union(place.geometry.viewport);
    } else {
      bounds.extend(place.geometry.location);
    }
  });

  map.fitBounds(bounds);
  setSearchOpen(false);
  setInputAddress("");
};
