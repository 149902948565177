/*
 * This reducer is used to store the user's information like userId, name... every time fetch user success would trigger
 * the middleware to set up the request header with correct authorized token, the log-in status is also stored in this
 * reducer, every time the user log out would change the authorized header back to empty*/

import { AnyAction, createReducer } from "@reduxjs/toolkit";
import {
  authorizeUser,
  fetchUserFailed,
  fetchUserRequest,
  fetchUserSuccess,
  removeUser,
  signupUserFailed,
  signupUserRequest,
  signupUserSuccess,
} from "../actions";
import { UserState } from "../../../types/User";

const initialState = {
  user: null,
  loading: false,
  errMessage: "",
} as UserState;

const userReducer = createReducer(initialState, {
  [fetchUserRequest.type]: (state: UserState, action: AnyAction) => {
    Object.assign(state, { loading: true, errMessage: "" });
  },
  [fetchUserSuccess.type]: (state: UserState, action: AnyAction) => {
    Object.assign(state, { user: action.payload, loading: false });
  },
  [fetchUserFailed.type]: (state: UserState, action: AnyAction) => {
    Object.assign(state, { errMessage: action.payload, loading: false });
  },
  [signupUserRequest.type]: (state: UserState, action: AnyAction) => {
    console.log("in the reducer");
    Object.assign(state, { loading: true, errMessage: "" });
  },
  [signupUserSuccess.type]: (state: UserState, action: AnyAction) => {
    Object.assign(state, { user: action.payload, loading: false });
  },
  [signupUserFailed.type]: (state: UserState, action: AnyAction) => {
    Object.assign(state, { errMessage: action.payload, loading: false });
  },
  [removeUser.type]: (state: UserState, action: AnyAction) => {
    return initialState;
  },
  [authorizeUser.type]: (state: UserState, action: AnyAction) => {
    Object.assign(state, {
      user: {
        userEmail: action.payload.userEmail,
        authToken: action.payload.authToken,
        password: "",
      },
      loading: false,
      errMessage: "",
    });
  },
});
export default userReducer;
