import { MarkerWithId } from "./Map";
import { useAppSelector } from "../../redux/hooks";
import areaIcon from "../../icons/property/area.svg";
import numberRavenIcon from "../../icons/property/numberRaven.svg";
import trendIcon from "../../icons/property/trend.svg";
import popupTempImg from "../../icons/property/markerTempImg.png";

export const PopupComp = ({ markers, markerSelected }) => {
  const properties = useAppSelector((state) => state.property.properties);
  return (
    <div style={{ visibility: "hidden", width: "0", height: "0" }}>
      {markers.map((marker: MarkerWithId, index) => {
        // @ts-ignore
        const selectedProperty = properties[markerSelected];

        return (
          <div id={`marker-${index}`} key={index}>
            <div className="popup-header">
              <div className="popup-price">
                $ {selectedProperty?.minPrice} - $ {selectedProperty?.maxPrice}
              </div>
              <a className="popup-expand" id="popup-drawer">
                Expand
              </a>
            </div>

            <img
              src={popupTempImg}
              height="160px"
              style={{ marginLeft: "-5px" }}
              alt={"no popup"}
            />
            <div className="popup-address">{selectedProperty?.address}</div>
            <div
              style={{
                border: "1px solid #F0F0F0",
                margin: "-5px",
              }}
            />
            <div className="popup-footer">
              <div className="popup-footer-content">
                <img src={areaIcon} alt={"no area icon"} />
                {selectedProperty?.area} m2
              </div>
              <div style={{ border: "1px solid #F0F0F0", marginTop: "5px" }} />
              <div className="popup-footer-content">
                <img src={numberRavenIcon} alt={"no number raven Icon"} />
                {selectedProperty?.ravenNumber}%
              </div>
              <div style={{ border: "1px solid #F0F0F0", marginTop: "5px" }} />
              <div className="popup-footer-content">
                <img src={trendIcon} alt={"no trend icon"} />
                {selectedProperty?.annualGrowth}%
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
