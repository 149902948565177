//add marker listener to the map to change marker state
import { coordinateToAddress } from "./setInputMarker";
import { searchBoxControl } from "./searchBoxControl";
import _ from "lodash";

const setDebouncedBound = _.debounce((setMapBounds, map) => {
  setMapBounds(map.getBounds() as google.maps.LatLngBounds);
}, 400);

export const initEventListener = (
  map,
  setAddress,
  mapBounds,
  setSearchOpen,
  setInputAddress,
  setMapBounds,
  setMarkerSelected,
  setPropertyDrawerOpen
) => {
  let clickListener;
  let searchBoxListener;
  let boundListener;
  let dblClickListener;
  if (map) {
    // add marker function
    clickListener = google.maps.event.addListener(map, "click", function (e) {
      setMarkerSelected(null);
      setPropertyDrawerOpen(false);
    });
    dblClickListener = google.maps.event.addListener(
      map,
      "dblclick",
      function (e) {
        coordinateToAddress(e.latLng, setAddress,map);
      }
    );
    //search box function
    const input = document.getElementById("mapSearchInput") as HTMLInputElement;
    if (input) {
      const searchBox = new google.maps.places.SearchBox(input);
      searchBox.setBounds(mapBounds as google.maps.LatLngBounds);
      searchBoxListener = searchBox.addListener("places_changed", () => {
        searchBoxControl(map, searchBox, setSearchOpen, setInputAddress);
      });
    }
    // set the width of the search box

    // get bounds of the map
    boundListener = google.maps.event.addListener(map, "bounds_changed", () => {
      setDebouncedBound(setMapBounds, map);
      setPropertyDrawerOpen(false);
      setMarkerSelected(null);
    });
  }

  return () => {
    google.maps.event.removeListener(clickListener);
    google.maps.event.removeListener(searchBoxListener);
    google.maps.event.removeListener(boundListener);
    google.maps.event.removeListener(dblClickListener);
  };
};
