import { configureStore } from "@reduxjs/toolkit";
import propertyReducer from "./reducers/propertyReducer";
import userReducer from "./reducers/userReducer";
import { apiMiddleware } from "./middlewares/api-middleware";
import logger from "redux-logger";

export const store = configureStore({
  reducer: {
    user: userReducer,
    property: propertyReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["error", "meta"],
      },
    })
      .concat(apiMiddleware)
      .concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type StoreType = typeof store;
