import {
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { Card, Button } from "react-bootstrap";

import info from "../../../icons/info.svg";

export const Features = ({
  setAccordionActiveKey,
  featureInput,
  newForm,
  setValue,
}) => {
  return (
    <>
      <Card.Body
        className="createAccountCardBodyRow row"
        style={{ paddingTop: "0" }}
      >
        <div className="col-12 col-sm-6">
          <InputLabel className="createAccountLabel">Repayment Type</InputLabel>
          <Select
            className="form-input"
            value={featureInput.repaymentType.value}
            onChange={(evt) => {
              setValue("repaymentType", evt.target.value);
            }}
            disableUnderline={true}
            placeholder="select"
          >
            <MenuItem value="Interest Only">Interest Only</MenuItem>
            <MenuItem value="Principal and Interest">
              Principal and Interest
            </MenuItem>
          </Select>
        </div>
        <div className="col-12 col-sm-6">
          <InputLabel className="createAccountLabel">
            Interest Only Term (Years)
          </InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            className="form-input"
            type="number"
            value={featureInput.interestYear.value}
            onChange={(evt) => {
              setValue("interestYear", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {featureInput.interestYear.errMessage && (
            <a className="validatingHintIncome">
              {featureInput.interestYear.errMessage}
            </a>
          )}
        </div>

        <div className="col-12 col-sm-6">
          <InputLabel className="createAccountLabel">Loan Amount</InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            type="number"
            className="form-input"
            value={featureInput.loan.value}
            onChange={(evt) => {
              setValue("loan", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {featureInput.loan.errMessage && (
            <a className="validatingHintIncome">
              {featureInput.loan.errMessage}
            </a>
          )}
        </div>
        <div className="col-12 col-sm-6">
          <InputLabel className="createAccountLabel">
            Loan Term (Years)
          </InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            className="form-input"
            type="number"
            value={featureInput.loanTerm.value}
            onChange={(evt) => {
              setValue("loanTerm", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {featureInput.loanTerm.errMessage && (
            <a className="validatingHintIncome">
              {featureInput.loanTerm.errMessage}
            </a>
          )}
        </div>
        <div className="col-12 col-sm-6">
          <InputLabel className="createAccountLabel">
            Interest Rate (%)
          </InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            type="number"
            className="form-input"
            value={featureInput.interestRate.value}
            onChange={(evt) => {
              setValue("interestRate", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {featureInput.interestRate.errMessage && (
            <a className="validatingHintIncome">
              {featureInput.interestRate.errMessage}
            </a>
          )}
        </div>
      </Card.Body>
      <Card.Footer className="createAccountCardFooter">
        <Button
          className="formButton "
          style={{
            marginLeft: "40%",
            width: "25%",
            color: "#D2DEF9",
            backgroundColor: "white",
            borderColor: "#D2DEF9",
          }}
          onClick={() => setAccordionActiveKey("1")}
        >
          Back
        </Button>
        <Button
          className="formButton "
          style={{
            marginLeft: "5%",
            width: "25%",
            color: "white",
          }}
        >
          Next
        </Button>
      </Card.Footer>
    </>
  );
};
