export const mapInitiate = (element, mapType, mapTypeControl) => {
  const defaultAddress = new google.maps.LatLng(-37.840935, 144.946457);
  return new google.maps.Map(element, {
    zoom: 13,
    center: defaultAddress,
    mapTypeControl: mapTypeControl,
    streetViewControl: false,
    rotateControl: false,
    scaleControl: true,
    fullscreenControl: false,
    panControl: false,
    zoomControl: true,
    disableDoubleClickZoom: true,
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_TOP,
    },
    gestureHandling: "cooperative",
    mapTypeId: mapType,
    draggableCursor: "pointer",
  });
};
