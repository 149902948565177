import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { MainPage } from "./pages/MainPage";

const App = () => {
  return (
    <div>
      <MainPage />
    </div>
  );
};

export default App;
