import { SortBy } from "./AllListings";

export const ListingSort = ({ setSortByOption }) => {
  return (
    <div className="space-between-horizontal">
      <div className="description">12 Sites Listed</div>
      <div className="sort">
        Sort By
        <select
          name="sortBy"
          id="sortBy"
          onChange={(evt) => setSortByOption(evt.target.value as SortBy)}
        >
          <option value={SortBy.Newest}>{SortBy.Newest}</option>
          <option value={SortBy.Highest}>{SortBy.Highest}</option>
          <option value={SortBy.Lowest}>{SortBy.Lowest}</option>
          <option value={SortBy.Largest}>{SortBy.Largest}</option>
          <option value={SortBy.Smallest}>{SortBy.Smallest}</option>
        </select>
      </div>
    </div>
  );
};
