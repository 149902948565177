import WatchListIcon from "../../../icons/filterAndList/Watchlist.svg";
import { useState } from "react";
import { ListCard } from "./ListCard";
export const WatchList = () => {
  const [searchOption, setSearchOption] = useState<string>();
  const [searchAddress, setSearchAddress] = useState<string>();
  const tempList = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className="bottom-drawer">
      <div className="filter-and-list-header">
        <img src={WatchListIcon} />
        Watchlist
      </div>
      <div className="list-search">
        <div className="header">
          <div className="items">52 Items</div>
          <div className="hint-text">Updated 03/03/2021 at 2:22PM</div>
        </div>
        <div className="search-bar">
          <select
            className="select"
            onChange={(evt) => {
              setSearchOption(evt.target.value);
            }}
          >
            <option>Address</option>
          </select>
          <input
            className="input"
            placeholder="Search by Address"
            onChange={(evt) => setSearchAddress(evt.target.value)}
          />
        </div>
      </div>
      <div className="list-scroll">
        {tempList.map((el) => {
          return (
            <div style={{ display: "block", height: "10vw" }}>
              <ListCard />
            </div>
          );
        })}
      </div>
    </div>
  );
};
