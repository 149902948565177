import { ListingTag } from "./AllListings";

export const ListingNav = ({ listingTag, setListingTag }) => {
  return (
    <div className="four-even-tag-horizontal">
      <div
        onClick={() => setListingTag(ListingTag.Sold)}
        className={listingTag === ListingTag.Sold ? "selected" : ""}
      >
        {ListingTag.Sold}
      </div>
      <div
        onClick={() => setListingTag(ListingTag.Ongoing)}
        className={listingTag === ListingTag.Ongoing ? "selected" : ""}
      >
        {ListingTag.Ongoing}
      </div>
      <div
        onClick={() => setListingTag(ListingTag.Pending)}
        className={listingTag === ListingTag.Pending ? "selected" : ""}
      >
        {ListingTag.Pending}
      </div>
      <div
        onClick={() => setListingTag(ListingTag.Coming)}
        className={listingTag === ListingTag.Coming ? "selected" : ""}
      >
        {ListingTag.Coming}
      </div>
    </div>
  );
};
