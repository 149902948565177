import { Property } from "../../../../types/Property";
import { FunctionComponentElement } from "react";

export const PropertyDetails = ({
  selectedProperty,
}): FunctionComponentElement<Property | null> => {
  return (
    <div className="property-body">
      <div style={{ height: "33vh", flexDirection: "row", display: "flex" }}>
        <div className={"title-vertical"}>Planning Details</div>
        <div className={"detail-container"}>
          <div className={"detail-row"}>
            <div className={"detail-col"}>
              ZONE
              <br />
              <div>{selectedProperty?.zone || "-"}</div>
            </div>
            <div className={"detail-col"}>
              LAND AREA <br />
              <div>{selectedProperty?.area || "-"}</div>
            </div>
          </div>
          <div className={"detail-row"}>
            <div className={"detail-col"}>
              COUNCIL <br />
              <div>{selectedProperty?.council || "-"}</div>
            </div>
            <div className={"detail-col"}>
              LOT ID <br />
              <div>{selectedProperty?._id || "-"}</div>
            </div>
          </div>
          <div className={"detail-row"}>
            <div className={"detail-col"}>
              MIN LOT SIZE <br />
              <div>{selectedProperty?.minLotSize || "-"}</div>
            </div>
            <div className={"detail-col"}>
              MAX BUILDING HEIGHT <br />
              <div>{selectedProperty?.maxPropertyHeight || "-"}</div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "22vh", flexDirection: "row", display: "flex" }}>
        <div className={"title-vertical"}>Property Attributes</div>
        <div className={"detail-container"}>
          <div className={"detail-row"}>
            <div className={"detail-col"}>
              ADDRESS
              <br />
              <div>{selectedProperty?.address || "-"}</div>
            </div>
            <div className={"detail-col"}>
              YEAR BUILT
              <br />
              <div>{selectedProperty?.yearBuilt || "-"}</div>
            </div>
          </div>

          <div className={"detail-row"}>
            <div className={"detail-col"}>
              OCCUPANCY
              <br />
              <div>?</div>
            </div>
            <div className={"detail-col"}>
              OWNERSHIP
              <br />
              <div>{selectedProperty?.ownerId || "-"}</div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "22vh", flexDirection: "row", display: "flex" }}>
        <div className={"title-vertical"}>Sales History</div>
        <div className={"detail-container"}>
          <div className={"detail-row"}>
            <div className={"detail-col"}>
              LAST SALE DATE
              <br />
              <div>?</div>
            </div>
            <div className={"detail-col"}>
              SALE PRICE
              <br />
              <div>
                {selectedProperty?.minPrice +
                  " - " +
                  selectedProperty?.maxPrice || "-"}
              </div>
            </div>
          </div>
          <div className={"detail-row"}>
            <div className={"detail-col"}>
              AGENT OR AGENCY
              <br />
              <div>{"?" || "-"}</div>
            </div>
            <div className={"detail-col"}>
              % INCREASE/DECREASE
              <br />
              <div>{selectedProperty?.annualGrowth + "%" || "-"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
