// file
import React, { useEffect, useState } from "react";
import "../css/createAccount.scss";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { Header } from "../components/createaccount/Header";
import { Menu } from "../components/createaccount/Menu";
import { Detail } from "../components/createaccount/Detail";
import { BorrowCapacity } from "../components/createaccount/BorrowCapacity";
import { CoDeveloper } from "../components/createaccount/CoDeveloper";
import { SendRequest } from "../components/createaccount/SendRequest";
import { useExpenseInput } from "../hooks/useExpenseInput";
import { useFeatureInput } from "../hooks/useFeatureInput";
import { useIncomeInput } from "../hooks/useIncomeInput";
import { useCreateAccount } from "../hooks/useCreateAccount";

const CreateAccount = () => {
  const match = useRouteMatch();
  const [expenseInput, expenseNewForm, expenseSetValue] = useExpenseInput();
  const [featureInput, featureNewForm, featureSetValue] = useFeatureInput();
  const [incomeInput, incomeNewForm, incomeSetValue] = useIncomeInput();
  const [coDevArray, setCoDevArray] = useState([
    { name: {}, email: {}, phone: {} },
  ]);
  const [createAccount, newForm, setValue] = useCreateAccount();

  const borrowState = {
    expenseInput,
    expenseNewForm,
    expenseSetValue,
    featureInput,
    featureNewForm,
    featureSetValue,
    incomeInput,
    incomeNewForm,
    incomeSetValue,
  };

  const allSignUpStates = {
    expenseInput,
    featureInput,
    incomeInput,
    coDevArray,
    createAccount,
  };

  return (
    <div className="containerCreateAccount">
      <Header />
      <div className="createAccountBody">
        <Menu />
        <Switch>
          <Route path={`${match.path}/detail`}>
            <Detail
              createAccount={createAccount}
              newForm={newForm}
              setValue={setValue}
            />
          </Route>
          <Route path={`${match.path}/borrowcapacity`}>
            <BorrowCapacity borrowState={borrowState} />
          </Route>
          <Route path={`${match.path}/codeveloper`}>
            <CoDeveloper
              coDevArray={coDevArray}
              setCoDevArray={setCoDevArray}
              allSignUpStates={allSignUpStates}
            />
          </Route>
          <Route path={`${match.path}/sendrequest`}>
            <SendRequest />
          </Route>
          <Route path={match.path}>
            <Detail
              createAccount={createAccount}
              newForm={newForm}
              setValue={setValue}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default CreateAccount;
