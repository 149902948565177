import { useState } from "react";
import checkInput from "../utils/checkInput";
import _ from "lodash";

const initialState = {
  repaymentType: { value: "Interest Only", errMessage: "" },
  interestYear: { value: "", errMessage: "" },
  loan: { value: "", errMessage: "" },
  loanTerm: { value: "", errMessage: "" },
  interestRate: { value: "", errMessage: "" },
};

type FeatureInputKey =
  | "repaymentType"
  | "interestYear"
  | "loan"
  | "loanTerm"
  | "interestRate";

type FeatureInput = Record<
  FeatureInputKey,
  { value: string; errMessage?: string }
>;
type setFunction = (FeatureInputKey, string) => void;

const validateInput = (newValue, key, setFeatureInput) => {
  if (
    ["interestYear", "loanTerm"].includes(key) &&
    (!checkInput(newValue[key].value, "integer") || newValue[key].value > 100)
  )
    newValue = {
      ...newValue,
      [key]: {
        value: newValue[key].value,
        errMessage: "This must be a positive integer <100",
      },
    };
  if (
    (["loan", "interestRate"].includes(key) && newValue[key].value < 0) ||
    !newValue[key].value
  )
    newValue = {
      ...newValue,
      [key]: {
        value: newValue[key].value,
        errMessage: "This must be a positive number",
      },
    };

  setFeatureInput(newValue);
};

const debouceVerification = _.debounce(
  (expense, key, setFeatureInput) =>
    validateInput(expense, key, setFeatureInput),
  800
);

export const useFeatureInput = (): [FeatureInput, boolean, setFunction] => {
  const [featureInput, setFeatureInput] = useState(initialState);
  const [newForm, setNewForm] = useState(true);

  const setValue = (key, value) => {
    setNewForm(false);
    let newFeatureInput = { ...featureInput, [key]: { value } };

    //We have to define the deboucefn outside this setValue,
    //otherwise, every time setValue called, it will create another debouceVerification instance, which would have no effect to the previous debounce fn

    debouceVerification(newFeatureInput, key, setFeatureInput);

    setFeatureInput(newFeatureInput);
  };

  return [featureInput, newForm, setValue];
};
