import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import CreateAccount from "./CreateAccountPage";
import LoginPage from "./LogInPage";
import { RiseMap } from "./RiseMap";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { authorizeUser } from "../redux/actions";

export const MainPage = () => {
  const userState = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!userState) {
      const userEmail = sessionStorage.getItem("userEmail");
      const authToken = sessionStorage.getItem("authToken");

      if (userEmail && authToken)
        dispatch(authorizeUser({ userEmail, authToken }));
    }
  }, [userState]);
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/createaccount">
            <CreateAccount />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/risemap">
            <RiseMap />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

const Main = () => {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/login">login</Link>
        </li>
        <li>
          <Link to="/login/statuscheck">status check</Link>
        </li>
        <li>
          <Link to="/createaccount">create account</Link>
        </li>
        <li>
          <Link to="/risemap">rise map</Link>
        </li>
      </ul>
    </nav>
  );
};
