import { useState } from "react";
import Button from "@material-ui/core/Button";
import { InputBase } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import emailLogo from "../../icons/email.svg";
import { useHistory } from "react-router-dom";
import checkInput from "../../utils/checkInput";

const ForgetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [emailValid, setEmailValid] = useState<Boolean>(true);
  return (
    <div className="logInBox">
      <h1 className="headerText">Forgot your password?</h1>
      <a className="subtitle">
        Enter your email and we will send you a link to reset your password
      </a>
      <form className="logInForm">
        <InputBase
          id="email"
          placeholder="Enter your email"
          className="form-input"
          value={email}
          onChange={(evt) => {
            setEmail(evt.target.value);
            setEmailValid(checkInput(evt.target.value, "email"));
          }}
          startAdornment={
            <InputAdornment position="start">
              <img src={emailLogo} alt={"no email logo"} />
            </InputAdornment>
          }
        />
        {!emailValid && <a className="validatingHint">Invalid email format</a>}
        <Button
          className="form-button"
          onClick={() => {
            history.push("/login/sent");
          }}
        >
          Send Request
        </Button>
      </form>
    </div>
  );
};

export default ForgetPassword;
