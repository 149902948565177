import {
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { Card, Button } from "react-bootstrap";

import info from "../../../icons/info.svg";

export const Expense = ({
  setAccordionActiveKey,
  expenseInput,
  newForm,
  setValue,
}) => {
  return (
    <>
      <Card.Body
        className="createAccountCardBodyRow row"
        style={{ paddingTop: "0" }}
      >
        <div className="col-12 col-lg-6">
          <InputLabel className="createAccountLabel">Marital Status</InputLabel>
          <Select
            className="form-input"
            value={expenseInput.maritalStatus.value}
            onChange={(evt) => {
              setValue("maritalStatus", evt.target.value);
            }}
            disableUnderline={true}
            defaultValue="Married"
          >
            <MenuItem value="Married">Married</MenuItem>
            <MenuItem value="Single">Single</MenuItem>
            <MenuItem value="Widowed">Widowed</MenuItem>
            <MenuItem value="Divorced">Divorced</MenuItem>
            <MenuItem value="Defacto">Defacto</MenuItem>
          </Select>
        </div>

        <div className="col-12 col-lg-6">
          <InputLabel className="createAccountLabel">No. Dependents</InputLabel>
          <InputBase
            id="email"
            type="number"
            placeholder="Input field"
            className="form-input"
            value={expenseInput.noDependents.value}
            onChange={(evt) => {
              //debounce
              setValue("noDependents", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {expenseInput.noDependents.errMessage && (
            <a className="validatingHintIncome">
              {expenseInput.noDependents.errMessage}
            </a>
          )}
        </div>
        <div className="col-12 col-lg-6">
          <InputLabel className="createAccountLabel">
            Monthly Declared Expenses
          </InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            className="form-input"
            type="number"
            value={expenseInput.monthlyExpense.value}
            onChange={(evt) => {
              setValue("monthlyExpense", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {expenseInput.monthlyExpense.errMessage && (
            <a className="validatingHintIncome">
              {expenseInput.monthlyExpense.errMessage}
            </a>
          )}
        </div>
        <div className="col-12 col-lg-6">
          <InputLabel className="createAccountLabel">
            Other Mortgage Limits
          </InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            className="form-input"
            type="number"
            value={expenseInput.mortgageLimit.value}
            onChange={(evt) => {
              setValue("mortgageLimit", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {expenseInput.mortgageLimit.errMessage && (
            <a className="validatingHintIncome">
              {expenseInput.mortgageLimit.errMessage}
            </a>
          )}
        </div>
        <div className="col-12 col-lg-6">
          <InputLabel className="createAccountLabel">
            Other Monthly Repayments
          </InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            className="form-input"
            type="number"
            value={expenseInput.monthlyRepayment.value}
            onChange={(evt) => {
              setValue("monthlyRepayment", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {expenseInput.monthlyRepayment.errMessage && (
            <a className="validatingHintIncome">
              {expenseInput.monthlyRepayment.errMessage}
            </a>
          )}
        </div>
        <div className="col-12 col-lg-6">
          <InputLabel className="createAccountLabel">
            Credit Card Limits
          </InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            className="form-input"
            type="number"
            value={expenseInput.creditLimit.value}
            onChange={(evt) => {
              setValue("creditLimit", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />

          {expenseInput.creditLimit.errMessage && (
            <a className="validatingHintIncome">
              {expenseInput.creditLimit.errMessage}
            </a>
          )}
        </div>
      </Card.Body>
      <Card.Footer className="createAccountCardFooter">
        <Button
          className="formButton "
          style={{
            marginLeft: "40%",
            width: "25%",
            color: "#D2DEF9",
            backgroundColor: "white",
            borderColor: "#D2DEF9",
          }}
          onClick={() => setAccordionActiveKey("0")}
        >
          Back
        </Button>
        <Button
          className="formButton "
          style={{
            marginLeft: "5%",
            width: "25%",
            color: "white",
          }}
          onClick={() => setAccordionActiveKey("2")}
        >
          Next
        </Button>
      </Card.Footer>
    </>
  );
};
