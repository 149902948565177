/*
 * This reducer will store the state of the properties user can see on map, fetchPropertyRequest will be triggered
 * every time the front end window resizes, every time successfully updated properties will show on the front end
 * */
/*
 * This reducer is used to store the user's information like userId, name... every time fetch user success would trigger
 * the middleware to set up the request header with correct authorized token, the log-in status is also stored in this
 * reducer, every time the user log out would change the authorized header back to empty*/

import { AnyAction, createReducer } from "@reduxjs/toolkit";
import {
  fetchPropertyFailed,
  fetchPropertyRequest,
  fetchPropertySuccess,
  removeProperty,
} from "../actions";
import { PropertyState } from "../../../types/Property";

const initialState = {
  properties: [],
  loading: false,
  errMessage: "",
} as PropertyState;

const propertyReducer = createReducer(initialState, {
  [fetchPropertyRequest.type]: (state: PropertyState, action: AnyAction) => {
    Object.assign(state, { loading: true, errMessage: "" });
  },
  [fetchPropertySuccess.type]: (state: PropertyState, action: AnyAction) => {
    Object.assign(state, { properties: action.payload, loading: false });
  },
  [fetchPropertyFailed.type]: (state: PropertyState, action: AnyAction) => {
    Object.assign(state, { errMessage: action.payload, loading: false });
  },
  [removeProperty.type]: (state: PropertyState, action: AnyAction) => {
    return initialState;
  },
});
export default propertyReducer;
