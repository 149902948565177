import React, { FunctionComponentElement, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Property } from "../../../../types/Property";
import propertyHeaderIcon from "../../../icons/property/propertyHeader.svg";

import { SubPagesState } from "../PropertyDrawer";
import { ListForSale } from "./ListForSale";
import { Partners } from "./Partners";
import { Ownership } from "./Ownership";
import { Summary } from "./Summary";

export interface propertyHeaderProps {
  setSubPage: React.Dispatch<React.SetStateAction<any>>;
  selectedProperty: Property;
  subPage: SubPagesState;
}

export const PropertyHeader = ({
  setSubPage,
  selectedProperty,
  subPage,
}): FunctionComponentElement<propertyHeaderProps> => {
  const navToProgress = () => {
    setSubPage(SubPagesState.DevelopmentProgressPage);
  };
  const navToDetail = () => {
    setSubPage(SubPagesState.PropertyDetailsPage);
  };
  const navToComparison = () => {
    setSubPage(SubPagesState.ComparisonPage);
  };
  const navToValuation = () => {
    setSubPage(SubPagesState.ValuationPage);
  };

  const [listForSaleShow, setListForSaleShow] = useState<boolean>(false);
  const [partnersShow, setPartnersShow] = useState<boolean>(false);
  const [ownershipShow, setOwnershipShow] = useState<boolean>(false);
  const [summaryShow, setSummaryShow] = useState<boolean>(false);

  return (
    <div className="property-header">
      {listForSaleShow && (
        <ListForSale
          listForSaleShow={listForSaleShow}
          setListForSaleShow={setListForSaleShow}
        />
      )}
      {partnersShow && (
        <Partners
          partnersShow={partnersShow}
          setPartnersShow={setPartnersShow}
        />
      )}
      {ownershipShow && (
        <Ownership
          ownershipShow={ownershipShow}
          setOwnershipShow={setOwnershipShow}
        />
      )}
      {summaryShow && (
        <Summary summaryShow={summaryShow} setSummaryShow={setSummaryShow} />
      )}
      <div className="property-header-space-between">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <img
            src={propertyHeaderIcon}
            alt="property header icon"
            style={{ marginRight: "20px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="property-header-subtitle">
              {selectedProperty?.availability ? "Unclaimed" : "Claimed"}
            </div>
            <div className="property-header-text">
              {selectedProperty?.address}
            </div>
          </div>
        </div>
      </div>
      <div className="property-header-space-between">
        <Button
          className="property-header-button"
          onClick={() => {
            setSummaryShow(true);
          }}
        >
          Start this Project
        </Button>
        <Button
          className="property-header-button"
          onClick={() => {
            setListForSaleShow(true);
          }}
        >
          List for Sale
        </Button>
        <Button
          className="property-header-button"
          disabled={!selectedProperty?.availability}
          onClick={() => {
            setOwnershipShow(true);
          }}
        >
          Claim Ownership
        </Button>
        <Button
          className="property-header-button"
          onClick={() => {
            setPartnersShow(true);
          }}
        >
          Partners
        </Button>
      </div>

      <div className="property-header-navigation-res">
        <button
          className={
            subPage === SubPagesState.DevelopmentProgressPage
              ? "property-header-button-active-left"
              : "property-header-button-inactive"
          }
          onClick={() => {
            navToProgress();
          }}
        >
          Development progress
        </button>
        <button
          className={
            subPage === SubPagesState.PropertyDetailsPage
              ? "property-header-button-active"
              : "property-header-button-inactive"
          }
          onClick={() => {
            navToDetail();
          }}
        >
          Property Details
        </button>
        <button
          className={
            subPage === SubPagesState.ComparisonPage
              ? "property-header-button-active"
              : "property-header-button-inactive"
          }
          onClick={() => {
            navToComparison();
          }}
        >
          Comparison
        </button>
        <button
          className={
            subPage === SubPagesState.ValuationPage
              ? "property-header-button-active-right"
              : "property-header-button-inactive"
          }
          onClick={() => {
            navToValuation();
          }}
        >
          Valuation
        </button>
      </div>
    </div>
  );
};
