export const ProgressBar = ({ innerWidth, innerStrokeWidth, percentage }) => {
  const radius = (innerWidth - innerStrokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const percentageLength =
    (1 - percentage) * (radius - innerStrokeWidth / 2) * 2 * Math.PI;
  return (
    <div
      style={{ width: innerWidth, height: innerWidth, position: "relative" }}
    >
      <svg
        height={innerWidth}
        width={innerWidth}
        style={{ position: "absolute", zIndex: 11 }}
      >
        <circle
          className="moving-progress"
          stroke="#4F7BEA"
          fill="transparent"
          stroke-width={innerStrokeWidth}
          strokeDasharray={`${(radius - innerStrokeWidth / 2) * 2 * Math.PI} ${
            (radius - innerStrokeWidth / 2) * 2 * Math.PI
          }`}
          style={{ strokeDashoffset: percentageLength }}
          r={radius - innerStrokeWidth / 2}
          cx={innerWidth / 2}
          cy={innerWidth / 2}
        />
      </svg>
      <svg
        height={innerWidth}
        width={innerWidth}
        style={{ position: "absolute", zIndex: 10 }}
      >
        <circle
          stroke="#F0F0F0"
          fill="transparent"
          stroke-width={innerStrokeWidth}
          r={radius}
          cx={innerWidth / 2}
          cy={innerWidth / 2}
        />
      </svg>
      <div className="progress-day">3d</div>
    </div>
  );
};
