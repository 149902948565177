import MySiteIcon from "../../../icons/filterAndList/MySite.svg";
import { useEffect, useState } from "react";
import { ListingTag, SortBy } from "./AllListings";
import { ListingNav } from "./ListingNav";
import { ListingSort } from "./ListingSort";
import { ListCard } from "./ListCard";

export const MyDevelopment = () => {
  const [listingTag, setListingTag] = useState<ListingTag>(ListingTag.Sold);
  const [sortByOption, setSortByOption] = useState<SortBy>(SortBy.Newest);
  const tempList = [1, 2, 3, 4, 5, 6, 7, 8];
  useEffect(() => {
    // console.log("sort by: ", sortByOption);
  }, [sortByOption]);
  return (
    <div className="bottom-drawer">
      <div className="filter-and-list-header">
        <img src={MySiteIcon} />
        My Sites
      </div>
      <ListingNav listingTag={listingTag} setListingTag={setListingTag} />
      <ListingSort setSortByOption={setSortByOption} />
      <div className="list-scroll">
        {tempList.map((el) => {
          return (
            <div style={{ display: "block", height: "10vw" }}>
              <ListCard />
            </div>
          );
        })}
      </div>
    </div>
  );
};
