/*
 * This middleware is used to process every api action*/

import {
  fetchPropertyFailed,
  fetchPropertyRequest,
  fetchPropertySuccess,
  fetchUserFailed,
  fetchUserRequest,
  fetchUserSuccess,
  removeUser,
  signupUserFailed,
  signupUserRequest,
  signupUserSuccess,
} from "../actions";
import { AppDispatch, store, StoreType } from "../store";
import { AnyAction, Middleware } from "@reduxjs/toolkit";
import API from "../../utils/api";

const actionMap = {
  [fetchUserRequest.type]: [fetchUserSuccess, fetchUserFailed],
  [signupUserRequest.type]: [signupUserSuccess, signupUserFailed],
  [fetchPropertyRequest.type]: [fetchPropertySuccess, fetchPropertyFailed],
};
export const apiMiddleware: Middleware =
  (store) => (next: AppDispatch) => (action: AnyAction) => {
    next(action);
    const apiAction = actionMap[action.type];

    if (
      action.type === fetchUserSuccess.type ||
      action.type === signupUserSuccess.type
    ) {
      const { payload } = action;
      API.defaults.headers.common["Authorization"] =
        "Bearer " + payload.authToken;
      sessionStorage.setItem("userEmail", payload.userEmail);
      sessionStorage.setItem("authToken", payload.authToken);
      const userEmailLocalStorage = localStorage.getItem("userEmail");
      const authTokenLocalStorage = localStorage.getItem("authToken");
      console.log("authToken is stored ", authTokenLocalStorage);
      return;
    }
    if (action.type === removeUser.type) {
      sessionStorage.clear();
    }

    if (!apiAction) {
      return;
    }
    const [actionSuccess, actionFail] = apiAction;
    const { httpMethod, path, data, params } = action.payload;

    const apiPromise = API.request({
      method: httpMethod,
      url: path,
      [httpMethod === "GET" ? "params" : "data"]:
        httpMethod === "GET" ? params : data,
    });

    apiPromise
      .then(({ data }) => {
        store.dispatch(actionSuccess(data));
      })
      .catch((err) => {
        store.dispatch(actionFail(err));
      });
  };
