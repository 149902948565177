import { createAction } from "@reduxjs/toolkit";
import {
  InitPropertyModel,
  LoginModel,
  SignupModel,
} from "../../types/schemas";
import { User } from "../../types/User";
import { Property } from "../../types/Property";
import { ApiPayload } from "../../types/ApiPayload";

// all the request using api-middleware is generated here
const generateRequest = <T, U>(actionName: string) => ({
  request: createAction<T>(actionName + "Request"),
  success: createAction<U>(actionName + "Success"),
  fail: createAction(actionName + "Fail", (error) => {
    return {
      payload: error.toString(),
    };
  }),
});
// TODO: copy the types into front end folder

export const {
  request: fetchUserRequest,
  success: fetchUserSuccess,
  fail: fetchUserFailed,
} = generateRequest<
  ApiPayload & { data: LoginModel },
  User & { authToken: string }
>("users/fetchUser");

export const {
  request: signupUserRequest,
  success: signupUserSuccess,
  fail: signupUserFailed,
} = generateRequest<
  ApiPayload & { data: SignupModel },
  User & { authToken: string }
>("users/signupUser");

export const {
  request: fetchPropertyRequest,
  success: fetchPropertySuccess,
  fail: fetchPropertyFailed,
} = generateRequest<ApiPayload & { params: InitPropertyModel }, Property[]>(
  "properties/fetchProperty"
);

export const removeUser = createAction("users/removeUser");
export const removeProperty = createAction("properties/removeProperty");
export const authorizeUser = createAction<Record<string, string>>(
  "users/authorizeUser"
);
