import React from "react";

import "../css/risemap.scss";
import MapComponent from "../components/risemap/Map";

export const RiseMap = () => {
  return (
    <div className="App">
      <MapComponent mapType="styled_map" mapTypeControl={false}></MapComponent>
    </div>
  );
};
