import { useState } from "react";
import checkInput from "../utils/checkInput";
import _ from "lodash";

const initialState = {
  name: { value: "", errMessage: "" },
  email: { value: "", errMessage: "" },
  phone: { value: "", errMessage: "" },
};

type CoDevInputKey = "name" | "email" | "phone";

type CoDevInput = Record<CoDevInputKey, { value: string; errMessage?: string }>;
type setFunction = (CoDevInputKey, string) => void;

const validateInput = (newValue, key, setCoDevInput) => {
  if (key === "name" && !checkInput(newValue[key].value, "name")) {
    newValue = {
      ...newValue,
      [key]: { value: newValue[key].value, errMessage: "Not a valid name" },
    };
  }
  if (key === "email" && !checkInput(newValue[key].value, "email")) {
    newValue = {
      ...newValue,
      [key]: { value: newValue[key].value, errMessage: "Not a valid email" },
    };
  }

  if (key === "phone" && !checkInput(newValue[key].value, "phone")) {
    newValue = {
      ...newValue,
      [key]: {
        value: newValue[key].value,
        errMessage: "Not a valid phone",
      },
    };
  }

  setCoDevInput(newValue);
};

const debouceVerification = _.debounce(
  (coDev, key, setCoDevInput) => validateInput(coDev, key, setCoDevInput),
  800
);

export const useCoDevInput = (
  initialStatePass
): [CoDevInput, boolean, setFunction] => {
  const [coDevInput, setCoDevInput] = useState(initialStatePass);
  const [newForm, setNewForm] = useState(true);

  const setValue = (key, value) => {
    setNewForm(false);
    const newCoDevInput = { ...coDevInput, [key]: { value } };

    //We have to define the deboucefn outside this setValue,
    //otherwise, every time setValue called, it will create another debouceVerification instance, which would have no effect to the previous debounce fn

    debouceVerification(newCoDevInput, key, setCoDevInput);

    setCoDevInput(newCoDevInput);
  };

  return [coDevInput, newForm, setValue];
};
