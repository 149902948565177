import crossIcon from "../../../icons/property/cross.svg";

export const Summary = ({ summaryShow, setSummaryShow }) => {
  return (
    <div
      className="rise-model"
      onClick={(evt) => {
        evt.preventDefault();
        if (evt.target === evt.currentTarget) setSummaryShow(false);
      }}
    >
      <div className="summary" style={{ overflow: "auto" }}>
        <div
          className="model-header"
          style={{ border: "none", marginBottom: 0 }}
        >
          <div>Summary</div>
          <img
            src={crossIcon}
            onClick={() => {
              setSummaryShow(false);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="model-body">
          <div>Review your steps and accept lorem ipsum dolor sip.</div>
          <div
            className="summary-sub-div"
            style={{
              height: "12vh",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div className="summary-row-space-between">
              <div className="project-progress-title">Project Progress</div>
              <div id="project-progress-money">$0 / $2,192,200 paid</div>
            </div>
            <div className="summary-row-justify-left">
              <div id="progress-1" />
              <div id="progress-2" />
              <div id="progress-3" />
              <div id="progress-4" />
            </div>
            <div className="summary-row-justify-left">
              <div className="row-align-center">
                <div id="step-1" />
                Step One
              </div>
              <div className="row-align-center">
                <div id="step-2" />
                Step Two
              </div>
              <div className="row-align-center">
                <div id="step-3" />
                Step Three
              </div>
            </div>
          </div>
          <div className="summary-sub-div">
            <div className="category-title">
              <div className="project-progress-title">Category</div>
              <a href="">Edit</a>
            </div>
            <div className="column-div">
              <div className="column-wrap">
                <div>
                  <div className="summary-subtitle">SUBTITLE</div>
                  <div className="summary-information">Information</div>
                </div>
                <div>
                  <div className="summary-subtitle">SUBTITLE</div>
                  <div className="summary-information">Information</div>
                </div>
                <div>
                  <div className="summary-subtitle">SUBTITLE</div>
                  <div className="summary-information">Information</div>
                </div>
                <div>
                  <div className="summary-subtitle">SUBTITLE</div>
                  <div className="summary-information">Information</div>
                </div>
              </div>
              <div className="total">
                <div style={{ width: "40%" }} />
                <div id="total-money">
                  <div className="summary-subtitle">TOTAL</div>
                  <div className="summary-number">$1,111,000</div>
                </div>
              </div>
            </div>
          </div>
          <div className="summary-sub-div">
            <div className="category-title">
              <div className="project-progress-title">Category</div>
              <a href="">Edit</a>
            </div>
            <div className="column-div">
              <div className="column-wrap">
                <div>
                  <div className="summary-subtitle">SUBTITLE</div>
                  <div className="summary-information">Information</div>
                </div>
                <div>
                  <div className="summary-subtitle">SUBTITLE</div>
                  <div className="summary-information">Information</div>
                </div>
                <div>
                  <div className="summary-subtitle">SUBTITLE</div>
                  <div className="summary-information">Information</div>
                </div>
                <div>
                  <div className="summary-subtitle">SUBTITLE</div>
                  <div className="summary-information">Information</div>
                </div>
              </div>
              <div className="total">
                <div style={{ width: "40%" }} />
                <div id="total-money">
                  <div className="summary-subtitle">TOTAL</div>
                  <div className="summary-number">$1,111,000</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="model-footer" style={{ border: "none" }}>
          <div className="footer-hint-text"></div>
          <div>
            <button
              className="button-decline"
              onClick={() => {
                setSummaryShow(false);
              }}
            >
              Close
            </button>
            <button
              className="button-accept"
              onClick={() => {
                setSummaryShow(false);
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
