import { ProgressBar } from "./ProgressBar";
import calenderIcon from "../../../icons/property/calender.svg";

export const DevelopmentProgress = () => {
  return (
    <div className="property-body">
      <div className="progress-div" style={{ marginTop: "2vw" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: "2vw",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className="valuation-font"
              style={{ color: "rgba(79, 123, 234, 1)" }}
            >
              Start Date
            </div>
            <div className="valuation-font" style={{ fontSize: "2vw" }}>
              Initial Development Phase
            </div>

            <div className="valuation-font">
              <img src={calenderIcon} style={{ marginRight: "1vw" }} />
              14 February 2021
            </div>
            <div>
              <ul style={{ color: "#BFBFBF", listStyle: "none" }}>
                <li>- attachment 1</li>
                <li>- attachment 2</li>
              </ul>
            </div>
          </div>
          <div>
            <ProgressBar
              innerWidth={60}
              innerStrokeWidth={5}
              percentage={0.8}
            />
          </div>
        </div>
      </div>
      <div className="progress-div">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
