export const getSubmitObject = (allSignUpStates) => {
  // err check
  let errMessage;
  for (const state in allSignUpStates) {
    if (Array.isArray(allSignUpStates[state])) {
      allSignUpStates[state].forEach((arrayElement) => {
        for (const element in arrayElement) {
          if (arrayElement[element].errMessage)
            errMessage = arrayElement.element.errMessage;
        }
      });
    }
    for (const element in allSignUpStates[state]) {
      if (!!allSignUpStates[state][element].errMessage)
        errMessage = allSignUpStates[state][element].errMessage;
    }
  }
  // construct the signIn model
  if (errMessage) {
    // TODO show err
    return;
  }
  const submitObject = {
    userInfo: {
      userEmail: allSignUpStates.createAccount.email.value,
      password: allSignUpStates.createAccount.reEnter.value,
      coDevList: allSignUpStates.coDevArray.map((el) => {
        return {
          fullName: el.name.value,
          email: el.email.value,
          phoneNumber: el.phone.value,
        };
      }),
    },
    borrowingCapacity: {
      expense: {
        maritalStatus: allSignUpStates.expenseInput.maritalStatus.value,
        noDependents: allSignUpStates.expenseInput.noDependents.value,
        monthlyExpense: allSignUpStates.expenseInput.monthlyExpense.value,
        otherMortgageLimits: allSignUpStates.expenseInput.mortgageLimit.value,
        otherMonthlyRepayments:
          allSignUpStates.expenseInput.monthlyRepayment.value,
        creditCardLimit: allSignUpStates.expenseInput.creditLimit.value,
      },
      mortgageFeature: {
        repaymentType: allSignUpStates.featureInput.repaymentType.value,
        interestTerm: allSignUpStates.featureInput.interestYear.value,
        loanAmount: allSignUpStates.featureInput.loan.value,
        loanTerm: allSignUpStates.featureInput.loanTerm.value,
        interestRate: allSignUpStates.featureInput.interestRate.value,
      },
      income: {
        grossIncome: allSignUpStates.incomeInput.grossIncome.value,
        rentalIncome: allSignUpStates.incomeInput.rentalIncome.value,
        otherTaxIncome: allSignUpStates.incomeInput.taxIncome.value,
        investmentLoanLimit: allSignUpStates.incomeInput.investment.value,
        otherNonTaxIncome: allSignUpStates.incomeInput.nonTaxIncome.value,
      },
    },
  };

  return submitObject;
};
