import mapmarker from "../../../icons/mapmarker.svg";
import { MarkerWithId } from "../Map";
import axios from "axios";

type GoogleLatLng = google.maps.LatLng;
type GoogleMarker = google.maps.Marker;
interface GoogleLatLngWithId {
  latLngPairArray: GoogleLatLng;
  propertyId: any;
}
type customizedLatLng = {
  LatLng: GoogleLatLng;
  propertyId: any;
};
let userInputMarker: google.maps.Marker[] = [];
// export let markers: google.maps.Marker[] = [];
let mapMarkers: MarkerWithId[] = [];
let pathCache;
export const setInputMarker = (
  map,
  setMarkers,
  clickLocation?: GoogleLatLng,
  propertyLocationList?: customizedLatLng[]
) => {
  if (clickLocation) {
    const clickMarker: GoogleMarker = new google.maps.Marker({
      position: clickLocation,
      // map: map,
      icon: {
        url: mapmarker,
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(20, 20),
      },
    });
    userInputMarker.push(clickMarker);
    for (let i = 0; i < userInputMarker.length; i++) {
      userInputMarker[i].setMap(null);
    }
    if (userInputMarker.length > 2) {
      userInputMarker.shift();
    }
    userInputMarker[userInputMarker.length - 1].setMap(map);
  }
  if (propertyLocationList) {
    for (let i = 0; i < mapMarkers.length; i++) {
      mapMarkers[i].marker?.setMap(null);
    }
    mapMarkers = [];
    for (const propertyLocation in propertyLocationList) {
      const propertyMarker: GoogleMarker = new google.maps.Marker({
        position: propertyLocationList[propertyLocation].LatLng,
        icon: {
          url: mapmarker,
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(20, 20),
        },
      });
      mapMarkers.push({
        marker: propertyMarker,
        propertyId: propertyLocationList[propertyLocation].propertyId,
      });
    }
    setMarkers(mapMarkers);
    for (let i = 0; i < mapMarkers.length; i++) {
      mapMarkers[i].marker?.setMap(map);
    }
  }
};

export const coordinateToAddress = async (
  coordinate: GoogleLatLng,
  setMarker,
  map
) => {
  const geocoder = new google.maps.Geocoder();
  await geocoder.geocode({ location: coordinate }, async function (results, status) {
    if (status === "OK" && results) {
      setMarker({
        address: results[0].formatted_address,
        latitude: coordinate.lat(),
        longitude: coordinate.lng(),
      });
      // call backend API here
      console.log("lat is ", coordinate.lat());
      console.log("lng is ", coordinate.lng());
      const postData= JSON.stringify({
        latitude: coordinate.lat(),
        longitude: coordinate.lng()
      })
      console.log("data post is ", postData);
      await axios.post('https://up.wallacedesign.com.au/polyengine',{
        latitude: coordinate.lat(),
        longitude: coordinate.lng()
      } )
          .then(function (response) {
            console.log(response.data);
            const formattedArray=response.data.map(el=>{
              return {lat: el[0],lng:el[1]}
            });
            console.log("formatted data is ", formattedArray);
            const path = new google.maps.Polygon({
              paths: formattedArray,
              geodesic: true,
              strokeColor: "#FF0000",
              strokeOpacity: 1.0,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.35,
            });
            if(!!pathCache){
              pathCache.setMap(null);
            }
            pathCache=path;
            pathCache.setMap(map)
          })
          .catch(function (error) {
            console.log(error.response.data);
          });
    }
  });
};

export const coordinateToAddressArray = async (
  coordinateArray: GoogleLatLngWithId[]
) => {
  const geocoder = new google.maps.Geocoder();
  const promiseList = coordinateArray.map((coordinate) => {
    const geocoderPromise = new Promise<any>((resolve) => {
      geocoder.geocode(
        { location: coordinate.latLngPairArray },
        function (results, status) {
          resolve({
            snippiestResult: results?.[0],
            propertyId: coordinate.propertyId,
          });
        }
      );
    });

    return geocoderPromise;
  });

  return Promise.all(promiseList).then((resultList) => {
    return resultList.map((result, key) => {
      return {
        address: result.snippiestResult?.formatted_address,
        latitude: coordinateArray[key].latLngPairArray.lat(),
        longitude: coordinateArray[key].latLngPairArray.lng(),
        propertyId: result.propertyId,
      };
    });
  });
};
