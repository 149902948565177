import React, { useEffect, useRef, useState } from "react";
import "../../css/risemap.scss";
import "../../css/listingAndFilter.scss";
import { styledMapType } from "../../constant/styledMapType";
import menu from "../../icons/menu.svg";
import menu_close from "../../icons/menu_close.svg";
import { DrawerComponent } from "./DrawerComponent";
import mapSearch from "../../icons/mapSearch.svg";
import {
  coordinateToAddressArray,
  setInputMarker,
} from "./mapFeatures/setInputMarker";
import { mapInitiate } from "./mapFeatures/mapInitiate";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchPropertyRequest } from "../../redux/actions";
import { initEventListener } from "./mapFeatures/initialListener";
import { PopupComp } from "./PopupComp";
import { CustomisedPopup } from "./mapFeatures/popup";
import { PropertyDrawer } from "./PropertyDrawer";

import { AllListings } from "./listingAndFilter/AllListings";
import { Filter } from "./listingAndFilter/Filter";
import { MyDevelopment } from "./listingAndFilter/MyDevelopment";
import { WatchList } from "./listingAndFilter/WatchList";

type Map = {
  mapType: string;
  mapTypeControl?: boolean;
};
type Address = {
  address: string;
  latitude: number;
  longitude: number;
  propertyId?: any;
};
type GoogleMap = google.maps.Map;
export type MarkerWithId = {
  marker?: google.maps.Marker;
  propertyId?: any;
};

export enum BelowDrawerClass {
  FullyClose = "drawer_below_close",
  HalfHide = "drawer_below_half_hide",
  HalfOpen = "drawer_below_half_open",
  FullyOpen = "drawer_below_open",
}
export enum DrawerButtonClass {
  FullyClose = "drawer_button ",
  HalfHide = "drawer_button_open",
  HalfOpen = "drawer_button_more_open",
  FullyOpen = "drawer_button_fully_open",
}

export enum ListAndFilterPage {
  AllListings = "AllListings",
  Filter = "Filter",
  MyDevelopment = "MyDevelopment",
  WatchList = "WatchList",
}
const listAndFilter = {
  AllListings: <AllListings />,
  Filter: <Filter />,
  MyDevelopment: <MyDevelopment />,
  WatchList: <WatchList />,
};

//init set up for the map
const MapComponent: React.FC<Map> = ({ mapType, mapTypeControl = false }) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const propertyState = useAppSelector((state) => state.property.properties);
  const [map, setMap] = useState<GoogleMap>();
  const [address, setAddress] = useState<Address>();
  const [markers, setMarkers] = useState<MarkerWithId[]>([]);
  const [addressArray, setAddressArray] = useState<Address[]>();
  const [drawerOpen, setDrawerOpen] = useState<Boolean>(false);
  const [searchOpen, setSearchOpen] = useState<Boolean>(false);
  const [inputAddress, setInputAddress] = useState<string>("");
  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds>();
  const [markerSelected, setMarkerSelected] = useState<number | null>(null);
  const [propertyDrawerOpen, setPropertyDrawerOpen] = useState<boolean>(false);
  const [belowDrawerClass, setBelowDrawerClass] = useState<BelowDrawerClass>(
    BelowDrawerClass.FullyClose
  );
  const [drawerButtonClass, setDrawerButtonClass] = useState<DrawerButtonClass>(
    DrawerButtonClass.FullyClose
  );
  const [drawSubPage, setDrawerSubPage] =
    useState<ListAndFilterPage | null>(null);

  useEffect(() => {
    const element = document.getElementById("mapview");
    if (element) {
      const map = mapInitiate(element, mapType, mapTypeControl);
      map.mapTypes.set("styled_map", styledMapType);
      setMap(map);
    }
  }, []);
  useEffect(() => {
    initEventListener(
      map,
      setAddress,
      mapBounds,
      setSearchOpen,
      setInputAddress,
      setMapBounds,
      setMarkerSelected,
      setPropertyDrawerOpen
    );
  }, [map]);
  useEffect(() => {
    if (address) {
      setInputMarker(
        map,

        setMarkers,
        new google.maps.LatLng(address.latitude, address.longitude)
      );
    }
  }, [address]);
  useEffect(() => {
    if (mapBounds) {
      dispatch(
        fetchPropertyRequest({
          path: "/initproperty/",
          httpMethod: "GET",
          params: {
            minLat: mapBounds.getSouthWest().lat().toString(),
            minLong: mapBounds.getSouthWest().lng().toString(),
            maxLat: mapBounds.getNorthEast().lat().toString(),
            maxLong: mapBounds.getNorthEast().lng().toString(),
          },
        })
      );
      const mainSearchBox = document.getElementsByClassName(
        "pac-container"
      )[0] as HTMLElement;
      if (mainSearchBox) {
        mainSearchBox.classList.add("search-box-1");
      }
    }
  }, [mapBounds]);
  useEffect(() => {
    propertyState.forEach(() => {});

    const latLngPairArray = propertyState.map((property) => {
      const latLngPairArray = new google.maps.LatLng(
        property.latitude,
        property.longitude
      );

      // @ts-ignore
      return { latLngPairArray, propertyId: property._id };
    });

    coordinateToAddressArray(latLngPairArray).then(
      (result: Address[] | undefined) => {
        if (result) {
          setAddressArray(result);
        }
      }
    );
  }, [propertyState]);
  useEffect(() => {
    const latLngArray = addressArray?.map((address) => {
      return {
        LatLng: new google.maps.LatLng(address.latitude, address.longitude),
        propertyId: address.propertyId,
      };
    });
    setInputMarker(map, setMarkers, undefined, latLngArray);
  }, [addressArray]);

  useEffect(() => {
    let markerListeners = markers.map((marker, index) => {
      return marker.marker?.addListener("click", () => {
        setMarkerSelected(index);
      });
    });

    return () => {
      markerListeners.forEach((markerListener) => {
        if (markerListener) google.maps.event.removeListener(markerListener);
      });
    };
  }, [markers]);

  useEffect(() => {
    let popupArray = [] as CustomisedPopup[];
    let popupListener;
    markers.forEach((marker, index) => {
      if (map && index === markerSelected) {
        const latitude = marker.marker?.getPosition()?.lat() as number;
        const longitude = marker.marker?.getPosition()?.lng() as number;
        const popupToBeRender = document.getElementById(`marker-${index}`);

        if (popupToBeRender) {
          let popup = new CustomisedPopup(
            new google.maps.LatLng(latitude, longitude),
            //@ts-ignore
            popupToBeRender.cloneNode(true)
          );

          popupListener = popup.containerDiv
            .querySelector("#popup-drawer")
            ?.addEventListener("click", () => {
              setPropertyDrawerOpen(true);
            });
          popup.setMap(map);
          popupArray.push(popup);
        }
      }
    });
    return () => {
      popupArray?.forEach((popup) => {
        popup.containerDiv
          .querySelector("#popup-drawer")
          ?.removeEventListener("click", popupListener);
        popup.setMap(null);
      });
    };
  }, [markerSelected]);

  return (
    <div className="map-container">
      <div ref={ref} id="mapview" className="map-container__map" />
      <DrawerComponent
        drawerOpen={drawerOpen}
        setDrawerButtonClass={setDrawerButtonClass}
        setBelowDrawerClass={setBelowDrawerClass}
        setDrawerSubPage={setDrawerSubPage}
      />
      <PropertyDrawer
        setPropertyDrawerOpen={setPropertyDrawerOpen}
        selectedMarker={markerSelected}
        propertyDrawerOpen={propertyDrawerOpen}
        mapBounds={mapBounds}
      />
      <div className={belowDrawerClass}>
        {drawSubPage && listAndFilter[drawSubPage]}
      </div>
      <button
        style={{ padding: 0, border: 0 }}
        onClick={() => {
          if (drawerButtonClass === DrawerButtonClass.FullyClose) {
            setDrawerButtonClass(DrawerButtonClass.HalfHide);
            setBelowDrawerClass(BelowDrawerClass.HalfHide);
            setDrawerOpen(!drawerOpen);
            return;
          }
          if (drawerButtonClass === DrawerButtonClass.HalfHide) {
            setDrawerButtonClass(DrawerButtonClass.FullyClose);
            setBelowDrawerClass(BelowDrawerClass.FullyClose);
            setDrawerOpen(!drawerOpen);
            return;
          }

          setDrawerButtonClass(DrawerButtonClass.HalfHide);
          setBelowDrawerClass(BelowDrawerClass.HalfHide);
        }}
      >
        <img
          src={drawerOpen ? menu_close : menu}
          className={drawerButtonClass}
          alt="no Img"
        />
      </button>
      <input
        className={searchOpen ? "mapsearching_end" : "mapsearching_start"}
        id="mapSearchInput"
        placeholder={searchOpen ? "Address" : ""}
        value={inputAddress}
        onChange={(evt) => {
          setInputAddress(evt.currentTarget.value);
        }}
      />
      <button
        style={{ padding: 0, border: 0 }}
        onClick={() => {
          setSearchOpen(!searchOpen);
        }}
      >
        <img
          src={mapSearch}
          className={searchOpen ? "mapSearchIcon_end" : "mapSearchIcon_start"}
          alt="no mapSearch"
        />
      </button>
      <PopupComp markers={markers} markerSelected={markerSelected} />
    </div>
  );
};

export default MapComponent;
