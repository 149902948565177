import { Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 22,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#4F7BEA",
        opacity: 1,
        border: "none",
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 22 / 2,
    border: `none`,
    backgroundColor: "#BFBFBF",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
  // @ts-ignore
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
