const emailFormat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const numbersFormat = /^-?\d*(\.\d+)?$/;
const integerFormat = /^[0-9]+$/;
const passwordFormat = new RegExp(
  "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
);
const letters = /^[A-Za-z]+$/;
const phone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

const validateEmail = (email) => {
  return emailFormat.test(String(email).toLowerCase());
};

const allNumeric = (inputText) => {
  return numbersFormat.test(inputText);
};
const allInteger = (inputText) => {
  return integerFormat.test(inputText);
};

const checkPassword = (password) => {
  return passwordFormat.test(password);
};

const allLetter = (inputText) => {
  return letters.test(inputText);
};

const validPhone = (input) => {
  return phone.test(input);
};

const validationMapping = {
  email: validateEmail,
  number: allNumeric,
  password: checkPassword,
  integer: allInteger,
  name: (input) => input.length < 20 && input.length > 2 && allLetter(input),
  phone: validPhone,
};

const checkInput = (
  input: string,
  checkingType: keyof typeof validationMapping
): Boolean => {
  return validationMapping[checkingType](input);
};

export default checkInput;
