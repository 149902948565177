import menuListMap from "../../constant/menuListMap";
import All_Listings from "../../icons/mapMenuButtons/All_Listings.svg";
import Comparison_Tool from "../../icons/mapMenuButtons/Comparison_Tool.svg";
import Filters from "../../icons/mapMenuButtons/Filters.svg";
import My_Developments from "../../icons/mapMenuButtons/My_Developments.svg";
import Search from "../../icons/mapMenuButtons/Search.svg";
import Watch_List from "../../icons/mapMenuButtons/Watch_List.svg";
import progressbar from "../../icons/mapMenuButtons/progressbar.svg";
import { BelowDrawerClass, DrawerButtonClass, ListAndFilterPage } from "./Map";
import { useEffect } from "react";

export const DrawerComponent = ({
  drawerOpen,
  setDrawerButtonClass,
  setBelowDrawerClass,
  setDrawerSubPage,
}) => {
  const controlBelowDrawer = (isHalf: boolean) => {
    if (isHalf) {
      setDrawerButtonClass(DrawerButtonClass.HalfOpen);
      setBelowDrawerClass(BelowDrawerClass.HalfOpen);
      return;
    }
    setDrawerButtonClass(DrawerButtonClass.FullyOpen);
    setBelowDrawerClass(BelowDrawerClass.FullyOpen);
  };
  useEffect(() => {
    setDrawerSubPage(null);
  }, [drawerOpen]);
  return (
    <div className={drawerOpen ? "drawer_show" : "drawer"}>
      <p className="menu_title">Developer Tool</p>
      <div className="divider" />
      <p className="list_title">map</p>
      <ul style={{ listStyleType: "none", padding: "0 " }}>
        <li className="list_item" onClick={() => {}}>
          <img src={Search} alt={"no Search"} />
          {menuListMap["search"].name}
        </li>
        <li
          className="list_item"
          onClick={() => {
            controlBelowDrawer(true);
            setDrawerSubPage(ListAndFilterPage.Filter);
          }}
        >
          <img src={Filters} alt={"no filter"} />
          {menuListMap["filter"].name}
        </li>
      </ul>
      <div className="divider" />
      <p className="list_title">developments</p>
      <ul style={{ listStyleType: "none", padding: "0 " }}>
        <li
          className="list_item"
          onClick={() => {
            controlBelowDrawer(false);
            setDrawerSubPage(ListAndFilterPage.AllListings);
          }}
        >
          <img src={All_Listings} alt={"no listing"} />
          {menuListMap["allListings"].name}
        </li>
        <li
          className="list_item"
          onClick={() => {
            controlBelowDrawer(false);
            setDrawerSubPage(ListAndFilterPage.WatchList);
          }}
        >
          <img src={Watch_List} alt={"no watch list"} />
          {menuListMap["watchList"].name}
        </li>
        <li
          className="list_item"
          onClick={() => {
            controlBelowDrawer(false);
            setDrawerSubPage(ListAndFilterPage.MyDevelopment);
          }}
        >
          <img src={My_Developments} alt={"no my development"} />
          {menuListMap["myDevelopment"].name}
        </li>
      </ul>
      <div className="divider" />
      <p className="list_title">admin</p>
      <ul style={{ listStyleType: "none", padding: "0 " }}>
        <li className="list_item" onClick={() => {}}>
          <img src={Comparison_Tool} alt={"no comparison"} />
          {menuListMap["comparisonTool"].name}
        </li>
      </ul>
      <div className="list_card">
        <a className="cardtitle">Borrowing Capacity</a>
        <a className="cardtext">Admin updated 09:12am</a>
        <a className="cardtext">May 01,2021</a>
        <a className="cardtext cardfooter">$2,091,410 | $3,024,500</a>
        <img
          className="progressbar"
          src={progressbar}
          alt={"no progress bar"}
        />
      </div>
    </div>
  );
};
