import ListingIcon from "../../../icons/filterAndList/Listing.svg";
import { useState } from "react";
import { ListingNav } from "./ListingNav";
import { ListingSort } from "./ListingSort";
import { ListCard } from "./ListCard";
export enum ListingTag {
  Sold = "SOLD",
  Ongoing = "ONGOING",
  Pending = "PENDING",
  Coming = "COMING SOON",
}
export enum SortBy {
  Newest = "Newest",
  Highest = "Highest Price",
  Lowest = "Lowest Price",
  Largest = "Largest Size",
  Smallest = "Smallest Size",
}
export const AllListings = () => {
  const [listingTag, setListingTag] = useState<ListingTag>(ListingTag.Sold);
  const [sortByOption, setSortByOption] = useState<SortBy>(SortBy.Newest);
  const tempList = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className="bottom-drawer">
      <div className="filter-and-list-header">
        <img src={ListingIcon} />
        Listings
      </div>
      <ListingNav listingTag={listingTag} setListingTag={setListingTag} />
      <ListingSort setSortByOption={setSortByOption} />
      <div className="list-scroll">
        {tempList.map((el) => {
          return (
            <div style={{ display: "block", height: "10vw" }}>
              <ListCard />
            </div>
          );
        })}
      </div>
    </div>
  );
};
