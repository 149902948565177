import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { ContextAwareToggle } from "./ContextAwareToggle";

import { Income } from "./BorrowCapacityComponents/Income";
import { Expense } from "./BorrowCapacityComponents/Expense";
import { Features } from "./BorrowCapacityComponents/Features";

export const BorrowCapacity = ({ borrowState }) => {
  const [accordionActiveKey, setAccordionActiveKey] = useState("");

  const {
    expenseInput,
    expenseNewForm,
    expenseSetValue,
    featureInput,
    featureNewForm,
    featureSetValue,
    incomeInput,
    incomeNewForm,
    incomeSetValue,
  } = borrowState;
  return (
    <div className="createAccountMain">
      <div className="createAccountMainHeader">
        <p className="stepHeader">Step Two</p>
        <p className="titleHeader">Borrowing Capacity</p>
      </div>
      <div className="createAccountMainContent">
        <h1 className="createAccountTitle">Calculate your capacity</h1>
        <p className="createAccountSubTitle">
          Determine your borrowing capacity with our calculator to access and
          start projects within your financial means.
        </p>
        <Accordion defaultActiveKey="0" activeKey={accordionActiveKey}>
          <Card className="createAccountCard" style={{ marginBottom: "10px" }}>
            <ContextAwareToggle
              eventKey="0"
              setAccordionActiveKey={setAccordionActiveKey}
            >
              Income Available
            </ContextAwareToggle>
            <Accordion.Collapse eventKey="0">
              <Income
                setAccordionActiveKey={setAccordionActiveKey}
                incomeInput={incomeInput}
                newForm={incomeNewForm}
                setValue={incomeSetValue}
              />
            </Accordion.Collapse>
          </Card>
          <Card className="createAccountCard" style={{ marginBottom: "10px" }}>
            <ContextAwareToggle
              eventKey="1"
              setAccordionActiveKey={setAccordionActiveKey}
            >
              Expenses
            </ContextAwareToggle>
            <Accordion.Collapse eventKey="1">
              <Expense
                setAccordionActiveKey={setAccordionActiveKey}
                expenseInput={expenseInput}
                newForm={expenseNewForm}
                setValue={expenseSetValue}
              />
            </Accordion.Collapse>
          </Card>
          <Card className="createAccountCard">
            <ContextAwareToggle
              eventKey="2"
              setAccordionActiveKey={setAccordionActiveKey}
            >
              Mortgage Features
            </ContextAwareToggle>
            <Accordion.Collapse eventKey="2">
              <Features
                setAccordionActiveKey={setAccordionActiveKey}
                featureInput={featureInput}
                newForm={featureNewForm}
                setValue={featureSetValue}
              />
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
};
