import { createRef } from "react";

export const SelectedConfiguration = ({
  index,
  setSelectedConfiguration,
  selectedConfiguration,
}) => {
  const thisRef = createRef<HTMLDivElement>();
  const goto = (divBeginner) => {
    const divBeginnerRect = divBeginner.current?.getBoundingClientRect();
    const container = document.getElementById("horizontal-2");
    if (container && divBeginnerRect) {
      container.scrollLeft += divBeginnerRect?.x - divBeginnerRect.width;
    }
  };
  // console.log("this ref is,", ref);
  return (
    <div
      className={"configuration-card"}
      style={
        index == selectedConfiguration
          ? { border: "1px solid #4F7BEA" }
          : { border: "1px solid #F0F0F0" }
      }
      onClick={() => {
        setSelectedConfiguration(index);
        if (thisRef.current) goto(thisRef);
      }}
      ref={thisRef}
    >
      <div className={"configuration-img"} />
      <div style={{ border: "1px solid #F0F0F0" }} />
      <div
        style={{ margin: "0.8vw", display: "flex", flexDirection: "column" }}
      >
        <div className={"configuration-header"}>NO. OF RAVENS</div>
        <div className={"configuration-text"}>? Ravens</div>
        <div className={"configuration-header"}>ESTIMATED COST</div>
        <div className={"configuration-text"}>$ ?</div>
        <div className={"configuration-header"}>ESTIMATED ROI</div>
        <div className={"configuration-text"}>? %</div>
      </div>
      <div style={{ border: "1px solid #F0F0F0" }} />
      <button className={"configuration-button"}>Start this Project</button>
    </div>
  );
};
