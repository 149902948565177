import { Accordion, Card, Button } from "react-bootstrap";
export const SendRequest=()=> {
  return (
    <div className="createAccountMain">
      <div className="createAccountMainHeader">
        <p className="stepHeader">Final Step</p>
        <p className="titleHeader">Sending your Request</p>
      </div>
      <div className="createAccountMainContent">
        <h1>Header1 option</h1>
        <p>subtitle</p>
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Click me!
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>Hello! I'm the body</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                Click me!
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>Hello! I'm another body</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
}
