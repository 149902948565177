import addActiveIcon from "../../../icons/filterAndList/add-active.svg";
import addInactiveIcon from "../../../icons/filterAndList/add-inactive.svg";
import likeActiveIcon from "../../../icons/filterAndList/like-active.svg";
import likeInactiveIcon from "../../../icons/filterAndList/like-inactive.svg";
import tempBuildingImg from "../../../icons/filterAndList/tempBuilding.png";
import { useState } from "react";
export const ListCard = () => {
  const [addActive, setAddActive] = useState<boolean>(false);
  const [likeActive, setLikeActive] = useState<boolean>(false);
  return (
    <div className="property-card">
      <img className="img" src={tempBuildingImg} />
      <div className="text">
        <div className="title">
          8079 Village Broad Street
          <div>
            <img
              src={addActive ? addActiveIcon : addInactiveIcon}
              style={{ marginRight: "0.5vw", cursor: "pointer" }}
              onClick={() => {
                setAddActive(!addActive);
              }}
            />
            <img
              src={likeActive ? likeActiveIcon : likeInactiveIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setLikeActive(!likeActive);
              }}
            />
          </div>
        </div>
        <div className="price-location">
          <div>$75,000-$1,200,090</div>
          MELBOURNE, VIC
        </div>
        <div className="tag">
          <div>-R3 RESIDENTIAL</div>
          <div>-1,025 M2</div>
        </div>
      </div>
    </div>
  );
};
