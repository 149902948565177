import crossIcon from "../../../icons/property/cross.svg";
export const ListForSale = ({ listForSaleShow, setListForSaleShow }) => {
  return (
    <div
      className="rise-model"
      onClick={(evt) => {
        evt.preventDefault();
        if (evt.target === evt.currentTarget) setListForSaleShow(false);
      }}
    >
      <div className="list-for-sale">
        <div className="model-header">
          <div>Would you like to list this property for sale?</div>
          <img
            src={crossIcon}
            onClick={() => {
              setListForSaleShow(false);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="model-body">
          All details regarding “8097 Village Broad St.” will no longer be
          tracker in your watchlist. Do you confirm?
        </div>

        <div className="model-footer">
          <div />
          <div>
            <button
              className="button-decline"
              onClick={() => {
                setListForSaleShow(false);
              }}
            >
              Decline
            </button>
            <button
              className="button-accept"
              onClick={() => {
                setListForSaleShow(false);
              }}
            >
              Yes, Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
