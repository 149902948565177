import { createRef, RefObject, useEffect, useState } from "react";
import rulerImg from "../../../icons/property/ruler.svg";
import tempComparison from "../../../icons/property/tempComparison.png";
import rulerIcon from "../../../icons/property/rulerIcon.svg";

export const Valuation = ({
  selectedProperty,
  addressArray,
  setAddressArray,
}) => {
  // const [refArray, setRefArray] = useState<RefObject<HTMLDivElement>[]>([]);
  const refArray = [] as RefObject<HTMLDivElement>[];
  addressArray.map((address) => {
    const currentRef = createRef<HTMLDivElement>();
    refArray.push(currentRef);
  });
  const [selectedCard, setSelectedCard] = useState<"head" | number>("head");
  const divBeginner = createRef<HTMLDivElement>();

  useEffect(() => {
    goto(divBeginner);
  }, []);

  const goto = (divBeginner) => {
    const divBeginnerRect = divBeginner.current?.getBoundingClientRect();
    const container = document.getElementById("horizontal");
    if (container && divBeginnerRect) {
      container.scrollLeft += divBeginnerRect?.x - divBeginnerRect.width / 2;
    }
  };

  return (
    <div className="property-body">
      <div style={{ height: "33vh", flexDirection: "row", display: "flex" }}>
        <div className="title-vertical">PROPERTY COMPARISON</div>
        <div className="selected-sites" id="horizontal">
          <div className="stick-dots">
            <div
              className={selectedCard === "head" ? "blue-bar" : "grey-dot"}
              onClick={() => {
                goto(divBeginner);
                setSelectedCard("head");
              }}
            />
            {addressArray.map((address, index) => {
              return (
                <div
                  className={selectedCard === index ? "blue-bar" : "grey-dot"}
                  onClick={() => {
                    goto(refArray[index]);
                    setSelectedCard(index);
                  }}
                />
              );
            })}

            <div />
          </div>
          <div
            className="selected-sites-card"
            style={
              selectedCard == "head"
                ? { border: "1px solid #4F7BEA", borderRadius: "5px" }
                : {}
            }
            ref={divBeginner}
            onClick={() => {
              setSelectedCard("head");
            }}
          >
            <div className="search-div">
              <img
                src={tempComparison}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="search-title">
              {selectedProperty?.address.split(",")[0]}
            </div>
          </div>
          {addressArray.map((address, index) => {
            return (
              <div
                className="selected-sites-card"
                style={
                  selectedCard == index
                    ? { border: "1px solid #4F7BEA", borderRadius: "5px" }
                    : {}
                }
                ref={refArray[index]}
                onClick={() => {
                  setSelectedCard(index);
                  if (index > 0) {
                    goto(refArray[index - 1]);
                    return;
                  }
                  goto(divBeginner);
                }}
              >
                <div
                  className="search-div"
                  style={{ padding: "0px !important" }}
                >
                  <img
                    src={tempComparison}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div className="search-title">{address.split(",")[0]}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          height: "33vh",
          flexDirection: "row",
          display: "flex",
          marginTop: "2vw",
        }}
      >
        <div className="title-vertical">ESTIMATED VALUATION</div>
        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "2vw" }}
        >
          <div className="rulerContainer">
            <div className="rulerValue">
              <div>$2M</div>
              <div>$1.8M</div>
              <div>$1.6M</div>
              <div>$1.4M</div>
              <div>$1.2M</div>
            </div>
            <div style={{ width: "20%" }}>
              <img src={rulerImg} height="98%" />
            </div>
            <div className="rulerDivider"></div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <img
                src={rulerIcon}
                width="20vw"
                style={{ position: "relative", top: "45%" }}
              />
            </div>
          </div>

          <div className="valuationCard">
            <div className="valuation-card-title valuation-font">
              Estimated Value Range
            </div>
            <div className="valuation-card-divider" />
            <div className="valuation-body">
              <div
                className="valuation-font"
                style={{
                  color: "rgba(79, 123, 234, 1)",
                  fontSize: "1.2vw",
                  marginTop: "1.3vw",
                }}
              >
                $1,630,000-$2,140,000
              </div>
              <div className="valuation-font valuation-tag">
                High Confidence
              </div>
              <div
                style={{ color: "rgba(191, 191, 191, 1)", fontSize: "0.5vw" }}
              >
                Last updated May 2021
              </div>
            </div>
          </div>
          <div className="valuationSideCard">
            <div className="valuation-font median-sale-price">
              Median Sale Price
            </div>
            <div className="valuation-card-divider" />
            <div
              style={{
                color: "#4F7BEA",
              }}
              className="valuation-font median-sale-price"
            >
              ~$1,870,000
            </div>
            <div className="valuation-card-divider" />
            <div className="valuation-font median-sale-price">
              Annual Growth
            </div>
            <div className="valuation-card-divider" />
            <div
              style={{
                marginBottom: "0.6vw",
                color: "#4F7BEA",
              }}
              className="valuation-font median-sale-price"
            >
              ↑4.7%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
