import crossIcon from "../../../icons/property/cross.svg";
import searchIcon from "../../../icons/property/search.svg";
import fakeAvator from "../../../icons/property/fakeAvator.png";

export const Partners = ({ partnersShow, setPartnersShow }) => {
  return (
    <div
      className="rise-model"
      onClick={(evt) => {
        evt.preventDefault();
        if (evt.target === evt.currentTarget) setPartnersShow(false);
      }}
    >
      <div className="partners">
        <div className="model-header">
          <div>Partners</div>
          <img
            src={crossIcon}
            onClick={() => {
              setPartnersShow(false);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="model-body">
          <div className="partners-input-div">
            Select from available partners and we’ll send a request to partner
            up.
            <div>
              <input placeholder="Search..."></input>
              <img src={searchIcon} />
            </div>
          </div>
          <div className="partners-body">
            <div className="partners-card ">
              <div className="title">Partner Name One</div>
              <div className="name">
                <img src={fakeAvator} width="15vw" />
                Vincent Ren
              </div>
              <div className="date">01/02/20, 15:25</div>
            </div>
            <div className="partners-card ">
              <div className="title">Partner Name One</div>
              <div className="name">
                <img src={fakeAvator} width="15vw" />
                Vincent Ren
              </div>
              <div className="date">01/02/20, 15:25</div>
            </div>
            <div className="partners-card ">
              <div className="title">Partner Name One</div>
              <div className="name">
                <img src={fakeAvator} width="15vw" />
                Vincent Ren
              </div>
              <div className="date">01/02/20, 15:25</div>
            </div>
            <div className="partners-card ">
              <div className="title">Partner Name One</div>
              <div className="name">
                <img src={fakeAvator} width="15vw" />
                Vincent Ren
              </div>
              <div className="date">01/02/20, 15:25</div>
            </div>
            <div className="partners-card ">
              <div className="title">Partner Name One</div>
              <div className="name">
                <img src={fakeAvator} width="15vw" />
                Vincent Ren
              </div>
              <div className="date">01/02/20, 15:25</div>
            </div>
          </div>
        </div>

        <div className="model-footer">
          <div className="footer-hint-text">0 Partners Selected</div>
          <div>
            <button
              className="button-decline"
              onClick={() => {
                setPartnersShow(false);
              }}
            >
              Close
            </button>
            <button
              className="button-accept"
              onClick={() => {
                setPartnersShow(false);
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
