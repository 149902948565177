import { useEffect } from "react";

import pillows from "../../icons/pillows.svg";
import { useHistory } from "react-router";

const StatusCheck = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/login/resetpassword");
    }, 6000);
  }, []);
  return (
    <div
      className="logInBox"
      style={{ justifyContent: "space-between", paddingTop: "72px" }}
    >
      <div style={{ maxWidth: "70%" }}>
        <h1 className="headerText">Checking Status...</h1>
        <a className="subtitle">
          Please wait while we check the status of your registration
        </a>
      </div>

      <img
        src={pillows}
        style={{ alignSelf: "center", width: "60%" }}
        alt={"no pillows"}
      />
    </div>
  );
};

export default StatusCheck;
