import { useContext } from "react";
import { AccordionContext, Card } from "react-bootstrap";
import arrowUp from "../../icons/arrowUp.svg";
import arrowDown from "../../icons/arrowDown.svg";

export const ContextAwareToggle = ({
  children,
  eventKey,
  setAccordionActiveKey,
}) => {
  const currentEventKey = useContext(AccordionContext);

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header
      className="createAccountCardHeader"
      style={{
        borderBottomLeftRadius: isCurrentEventKey ? "0px" : "10px",
        borderBottomRightRadius: isCurrentEventKey ? "0px" : "10px",
        backgroundColor: isCurrentEventKey ? "#f2f6ff" : "white",
      }}
      onClick={() => setAccordionActiveKey(eventKey)}
    >
      {children}
      <img src={isCurrentEventKey ? arrowUp : arrowDown} alt={"no arrow"} />
    </Card.Header>
  );
};
