import { InputBase } from "@material-ui/core";
import { useEffect } from "react";
import { Card, Button } from "react-bootstrap";

import cross from "../../icons/cross.svg";
import { useCoDevInput } from "../../hooks/useCoDevInput";
import { getSubmitObject } from "./getSumitObject";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { signupUserRequest } from "../../redux/actions";
import { useHistory } from "react-router";

export const CoDeveloper = ({ coDevArray, setCoDevArray, allSignUpStates }) => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user.user);
  const history = useHistory();
  useEffect(() => {
    if (userState && userState.authToken) history.push("/risemap");
  }, [userState]);

  return (
    <div className="createAccountMain">
      <div className="createAccountMainHeader">
        <p className="stepHeader">Step Three</p>
        <p className="titleHeader">Co-Developers </p>
      </div>
      <div className="createAccountMainContent">
        <h1 className="createAccountTitle">Add a Co-Developer</h1>
        <p className="createAccountSubTitle">
          Send invitations to any potential collaborators in any of your
          Wallace-related developments. They will be able to later contribute to
          your borrowing capacity as well as follow along with your progress.
        </p>

        <Card className="createAccountCard">
          <Card.Header className="createAccountCardHeader">
            Co-Developer 1
          </Card.Header>
          <Card.Body>
            {coDevArray.map((coDev, index) => {
              return (
                <CoDevDetail
                  coDev={coDev}
                  index={index}
                  coDevArray={coDevArray}
                  setCoDevArray={setCoDevArray}
                />
              );
            })}
            <Button
              className="addCoDevButton"
              onClick={() => {
                setCoDevArray([
                  ...coDevArray,
                  { name: "", email: "", phone: "" },
                ]);
              }}
            >
              + Add Co-Developer
            </Button>
          </Card.Body>
          <Card.Footer className="createAccountCardFooter">
            <Button
              className="formButton "
              style={{
                marginLeft: "40%",
                width: "25%",
                color: "#D2DEF9",
                backgroundColor: "white",
                borderColor: "#D2DEF9",
              }}
            >
              Skip
            </Button>
            <Button
              className="formButton "
              style={{
                marginLeft: "5%",
                width: "25%",
                color: "white",
              }}
              onClick={() => {
                const submitObject = getSubmitObject(allSignUpStates);
                console.log("submit object is ", submitObject);
                if (submitObject) {
                  console.log("before dispatch");
                  dispatch(
                    signupUserRequest({
                      path: "/signup",
                      httpMethod: "Post",
                      data: submitObject,
                    })
                  );
                }
              }}
            >
              Next
            </Button>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
};

const CoDevDetail = ({ index, coDev, coDevArray, setCoDevArray }) => {
  const [coDevInput, newForm, setValue] = useCoDevInput(coDevArray[index]);
  useEffect(() => {
    coDevArray[index] = coDevInput;
  }, [coDevInput]);
  return (
    <div key={index}>
      {index > 0 && (
        <Card.Header
          className="createAccountCardHeaderSquare"
          style={{ marginTop: "10px" }}
        >
          Co-Developer {index + 1}
          <img
            src={cross}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setCoDevArray(coDevArray.filter((el, key) => index !== key));
            }}
            alt={"no cross"}
          />
        </Card.Header>
      )}
      <InputBase
        id="fullname"
        placeholder="Fullname"
        className="form-input co-dev-name"
        value={coDevInput.name.value}
        onChange={(evt) => {
          setValue("name", evt.target.value);
        }}
      />
      <InputBase
        id="email"
        placeholder="Email"
        className="form-input co-dev-email"
        value={coDevInput.email.value}
        onChange={(evt) => {
          setValue("email", evt.target.value);
        }}
      />
      <InputBase
        id="phoneno"
        placeholder="Phone number"
        className="form-input co-dev-phone"
        value={coDevInput.phone.value}
        onChange={(evt) => {
          setValue("phone", evt.target.value);
        }}
      />
      {coDevInput.name.errMessage ? (
        <a className="validatingHintIncome">{coDevInput.name.errMessage}</a>
      ) : coDevInput.email.errMessage ? (
        <a className="validatingHintIncome">{coDevInput.email.errMessage}</a>
      ) : coDevInput.phone.errMessage ? (
        <a className="validatingHintIncome">{coDevInput.phone.errMessage}</a>
      ) : (
        <div />
      )}
    </div>
  );
};
