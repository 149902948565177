import React, { FunctionComponentElement, useEffect, useState } from "react";
import "../../css/propertyDrawer.scss";
import backArrow from "../../icons/property/backArrow.svg";
import { PropertyHeader } from "./propertyDrawer/PropertyHeader";
import { PropertyDetails } from "./propertyDrawer/PropertyDetails";
import { DevelopmentProgress } from "./propertyDrawer/DevelopmentProgress";
import { Comparison } from "./propertyDrawer/Comparison";
import { Valuation } from "./propertyDrawer/Valuation";
import { useAppSelector } from "../../redux/hooks";
import { Property } from "../../../types/Property";

export interface propertyDrawerProps {
  propertyDrawerOpen: boolean;
  selectedMarker: number;
  setPropertyDrawerOpen: any;
  mapBounds: google.maps.LatLngBounds;
}
export enum SubPagesState {
  PropertyDetailsPage = "PROPERTY DETAIL",
  ValuationPage = "VALUATION PAGE",
  ComparisonPage = "COMPARISON PAGE",
  DevelopmentProgressPage = "DEVELOPMENT PROGRESS PAGE",
}
export const PropertyDrawer = ({
  setPropertyDrawerOpen,
  propertyDrawerOpen,
  selectedMarker,
  mapBounds,
}): FunctionComponentElement<propertyDrawerProps> => {
  const [subPage, setSubPage] = useState<SubPagesState>(
    SubPagesState.DevelopmentProgressPage
  );
  const properties = useAppSelector((state) => state.property.properties);
  const [selectedProperty, setSelectedProperty] =
    useState<Property | null>(null);
  const [addressArray, setAddressArray] = useState<string[]>([]);

  useEffect(() => {
    setSelectedProperty(properties[selectedMarker]);
    console.log("selected property is ", properties[selectedMarker]);
  }, [selectedMarker]);
  useEffect(() => {
    // TODO verify if the property is owned by the user
    // TODO fetch the developments of the property
  }, [selectedProperty]);

  return (
    <div
      className={
        propertyDrawerOpen ? "property-drawer-show " : "property-drawer"
      }
    >
      <div
        onClick={() => {
          setPropertyDrawerOpen(false);
        }}
        className="property-drawer-back"
      >
        Back to Map
        <img src={backArrow} style={{ marginLeft: 10 }} />
      </div>
      <PropertyHeader
        setSubPage={setSubPage}
        selectedProperty={selectedProperty}
        subPage={subPage}
      />
      <div className="gradient-divider" />
      {subPage === SubPagesState.PropertyDetailsPage && (
        <PropertyDetails selectedProperty={selectedProperty} />
      )}
      {subPage === SubPagesState.DevelopmentProgressPage && (
        <DevelopmentProgress />
      )}
      {subPage === SubPagesState.ComparisonPage && (
        <Comparison
          mapBounds={mapBounds}
          selectedProperty={selectedProperty}
          addressArray={addressArray}
          setAddressArray={setAddressArray}
        />
      )}
      {subPage === SubPagesState.ValuationPage && (
        <Valuation
          selectedProperty={selectedProperty}
          addressArray={addressArray}
          setAddressArray={setAddressArray}
        />
      )}
    </div>
  );
};
