import { useState } from "react";
import checkInput from "../utils/checkInput";
import _ from "lodash";

const initialState = {
  maritalStatus: { value: "Married", errMessage: "" },
  noDependents: { value: "", errMessage: "" },
  monthlyExpense: { value: "", errMessage: "" },
  mortgageLimit: { value: "", errMessage: "" },
  monthlyRepayment: { value: "", errMessage: "" },
  creditLimit: { value: "", errMessage: "" },
};

type ExpenseInputKey =
  | "maritalStatus"
  | "noDependents"
  | "monthlyExpense"
  | "mortgageLimit"
  | "monthlyRepayment"
  | "creditLimit";

type ExpenseInput = Record<
  ExpenseInputKey,
  { value: string; errMessage?: string }
>;
type setFunction = (ExpenseInputKey, string) => void;

const validateInput = (newValue, key, setExpenseInput) => {
  if (
    key === "noDependents" &&
    (!checkInput(newValue.noDependents.value, "integer") ||
      parseInt(newValue.noDependents.value) > 20)
  )
    newValue = {
      ...newValue,
      [key]: {
        value: newValue[key].value,
        errMessage: "This must be a positive integer <20",
      },
    };

  if (
    (newValue[key].value < 0 || !newValue[key].value) &&
    [
      "monthlyExpense",
      "mortgageLimit",
      "monthlyRepayment",
      "creditLimit",
    ].includes(key)
  )
    newValue = {
      ...newValue,
      [key]: {
        value: newValue[key].value,
        errMessage: "This must be positive",
      },
    };
  setExpenseInput(newValue);
};

const debouceVerification = _.debounce(
  (expense, key, setExpenseInput) =>
    validateInput(expense, key, setExpenseInput),
  800
);

export const useExpenseInput = (): [ExpenseInput, boolean, setFunction] => {
  const [expenseInput, setExpenseInput] = useState(initialState);
  const [newForm, setNewForm] = useState(true);

  const setValue = (key, value) => {
    setNewForm(false);
    let newExpenseInput = { ...expenseInput, [key]: { value } };

    //We have to define the deboucefn outside this setValue,
    //otherwise, every time setValue called, it will create another debouceVerification instance, which would have no effect to the previous debounce fn

    debouceVerification(newExpenseInput, key, setExpenseInput);

    setExpenseInput(newExpenseInput);
  };

  return [expenseInput, newForm, setValue];
};
