import { useState } from "react";
import checkInput from "../utils/checkInput";
import _ from "lodash";

const initialState = {
  grossIncome: { value: "", errMessage: "" },
  rentalIncome: { value: "", errMessage: "" },
  taxIncome: { value: "", errMessage: "" },
  investment: { value: "", errMessage: "" },
  nonTaxIncome: { value: "", errMessage: "" },
};

type IncomeInputKey =
  | "grossIncome"
  | "rentalIncome"
  | "taxIncome"
  | "investment"
  | "nonTaxIncome";

type IncomeInput = Record<
  IncomeInputKey,
  { value: string; errMessage?: string }
>;
type setFunction = (IncomeInputKey, string) => void;

const validateInput = (newValue, key, setIncomeInput) => {
  if (newValue[key].value < 0 || !newValue[key].value)
    newValue = {
      ...newValue,
      [key]: {
        value: newValue[key].value,
        errMessage: "This must be a positive number",
      },
    };

  setIncomeInput(newValue);
};

const debouceVerification = _.debounce(
  (income, key, setIncomeInput) => validateInput(income, key, setIncomeInput),
  800
);

export const useIncomeInput = (): [IncomeInput, boolean, setFunction] => {
  const [incomeInput, setIncomeInput] = useState(initialState);
  const [newForm, setNewForm] = useState(true);

  const setValue = (key, value) => {
    setNewForm(false);
    let newIncomeInput = { ...incomeInput, [key]: { value } };

    //We have to define the deboucefn outside this setValue,
    //otherwise, every time setValue called, it will create another debouceVerification instance, which would have no effect to the previous debounce fn

    debouceVerification(newIncomeInput, key, setIncomeInput);

    setIncomeInput(newIncomeInput);
  };

  return [incomeInput, newForm, setValue];
};
