import FilterIcon from "../../../icons/filterAndList/Filter.svg";
import { IOSSwitch } from "./IOSSwitch";
import { useEffect, useState } from "react";
export const Filter = () => {
  // const [areaChecked, setAreaChecked] = useState<boolean>(false);
  // const [hotChecked, setHotChecked] = useState<boolean>(false);
  // const [budgetChecked, setBudgetChecked] = useState<boolean>(false);
  // const [saleChecked, setSaleChecked] = useState<boolean>(false);
  // const [rentalChecked, setRentalChecked] = useState<boolean>(false);
  // const [typeChecked, setTypeChecked] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<Record<string, boolean>>({
    areaChecked: false,
    hotChecked: false,
    budgetChecked: false,
    saleChecked: false,
    rentalChecked: false,
    typeChecked: false,
  });
  const getProps = (propName) => ({
    checked: filterOptions[propName],
    onClick: () => {
      setFilterOptions({
        ...filterOptions,
        [propName]: !filterOptions[propName],
      });
    },
  });

  return (
    <div className="filterDiv">
      <div className="filter-and-list-header">
        <img src={FilterIcon} />
        Filter
      </div>
      <div className="filter-list">
        Refine by
        <div className="column">
          <div className="filter-container">
            <div className="filter-item">
              Area
              <IOSSwitch {...getProps("areaChecked")} />
            </div>
            <div className="half-divider" />
            <div className="filter-item">
              What's Hot
              <IOSSwitch {...getProps("hotChecked")} />
            </div>
          </div>
        </div>
        Financial
        <div className="column">
          <div className="filter-container">
            <div className="filter-item">
              Budget
              <IOSSwitch {...getProps("budgetChecked")} />
            </div>
            <div className="half-divider" />
            <div className="filter-item">
              Highest Sale Price
              <IOSSwitch {...getProps("saleChecked")} />
            </div>
            <div className="half-divider" />
            <div className="filter-item">
              Highest Rental Yield
              <IOSSwitch {...getProps("rentalChecked")} />
            </div>
            <div className="half-divider" />
            <div className="filter-item">
              Project Type
              <IOSSwitch {...getProps("typeChecked")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
