import dualBall from "../../icons/dualBall.svg";
import arrowLeft from "../../icons/arrowLeft.svg";

export const Header=()=> {
  return (
    <div className="createAccountHeader">
      <div style={{ marginLeft: 108 }}>
        <a style={{ cursor: "pointer" }}>
          <img
            src={arrowLeft}
            onClick={() => {
              console.log("clicked!");
            }}
          />
        </a>
        <text
          className="headerText"
          style={{ marginLeft: 21, cursor: "pointer" }}
          onClick={() => {
            console.log("clicked!");
          }}
        >
          Back to Selection
        </text>
      </div>
      <img src={dualBall}></img>
      <text className="headerText" style={{ marginRight: 108 }}>
        Having trouble? <a href="">Get help</a>
      </text>
    </div>
  );
}
