import crossIcon from "../../../icons/property/cross.svg";
import searchIcon from "../../../icons/property/search.svg";
import fakeAvator from "../../../icons/property/fakeAvator.png";

export const Ownership = ({ ownershipShow, setOwnershipShow }) => {
  return (
    <div
      className="rise-model"
      onClick={(evt) => {
        evt.preventDefault();
        if (evt.target === evt.currentTarget) setOwnershipShow(false);
      }}
    >
      <div className="ownership" style={{ overflow: "auto" }}>
        <div className="model-header" style={{ border: "none" }}>
          <div>Claim Ownership</div>
          <img
            src={crossIcon}
            onClick={() => {
              setOwnershipShow(false);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="model-body" style={{ justifyContent: "space-around" }}>
          <div>Review your steps and accept lorem ipsum dolor sip.</div>
          <div className="ownership-input-div">
            <div className="ownership-input">
              EMAIL
              <input />
            </div>
            <div className="ownership-input">
              ENTITY NAME
              <input />
            </div>
            <div style={{ height: "1vw", width: "100%" }}></div>
            <div className="ownership-input">
              PHONE NUMBER
              <input />
            </div>
            <div className="ownership-input">
              CERTIFICATE OF TITLE
              <input />
            </div>
          </div>
        </div>

        <div className="model-footer" style={{ border: "none" }}>
          <div className="footer-hint-text"></div>
          <div>
            <button
              className="button-decline"
              onClick={() => {
                setOwnershipShow(false);
              }}
            >
              Close
            </button>
            <button
              className="button-accept"
              onClick={() => {
                setOwnershipShow(false);
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
