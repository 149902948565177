import { InputAdornment, InputBase, InputLabel } from "@material-ui/core";
import React from "react";
import { Card, Button } from "react-bootstrap";

import info from "../../../icons/info.svg";

export const Income = ({
  setAccordionActiveKey,
  incomeInput,
  newForm,
  setValue,
}) => {
  return (
    <>
      <Card.Body
        className="createAccountCardBodyRow row"
        style={{ paddingTop: "0" }}
      >
        <div className="col-12 col-sm-6">
          <InputLabel className="createAccountLabel">Gross Income</InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            className="form-input"
            type="number"
            value={incomeInput.grossIncome.value}
            onChange={(evt) => {
              setValue("grossIncome", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {incomeInput.grossIncome.errMessage && (
            <a className="validatingHintIncome">
              {incomeInput.grossIncome.errMessage}
            </a>
          )}
        </div>
        <div className="col-12 col-sm-6">
          <InputLabel className="createAccountLabel">Rental Income</InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            type="number"
            className="form-input"
            value={incomeInput.rentalIncome.value}
            onChange={(evt) => {
              setValue("rentalIncome", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {incomeInput.rentalIncome.errMessage && (
            <a className="validatingHintIncome">
              {incomeInput.rentalIncome.errMessage}
            </a>
          )}
        </div>

        <div className="col-12 col-sm-6">
          <InputLabel className="createAccountLabel">
            Other Taxable Income
          </InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            type="number"
            className="form-input"
            value={incomeInput.taxIncome.value}
            onChange={(evt) => {
              setValue("taxIncome", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {incomeInput.taxIncome.errMessage && (
            <a className="validatingHintIncome">
              {incomeInput.taxIncome.errMessage}
            </a>
          )}
        </div>
        <div className="col-12 col-sm-6">
          <InputLabel className="createAccountLabel">
            Investment Loan Limit(s)
          </InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            className="form-input"
            type="number"
            value={incomeInput.investment.value}
            onChange={(evt) => {
              setValue("investment", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {incomeInput.investment.errMessage && (
            <a className="validatingHintIncome">
              {incomeInput.investment.errMessage}
            </a>
          )}
        </div>
        <div className="col-12 col-sm-6">
          <InputLabel className="createAccountLabel">
            Other Non-Taxable Income
          </InputLabel>
          <InputBase
            id="email"
            placeholder="Input field"
            className="form-input"
            type="number"
            value={incomeInput.nonTaxIncome.value}
            onChange={(evt) => {
              setValue("nonTaxIncome", evt.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <img src={info} alt={"no info"} />
              </InputAdornment>
            }
          />
          {incomeInput.nonTaxIncome.errMessage && (
            <a className="validatingHintIncome">
              {incomeInput.nonTaxIncome.errMessage}
            </a>
          )}
        </div>
      </Card.Body>
      <Card.Footer className="createAccountCardFooter">
        <Button
          className="formButton "
          style={{
            marginLeft: "70%",
            width: "30%",
            color: "white",
          }}
          onClick={() => setAccordionActiveKey("1")}
        >
          Next
        </Button>
      </Card.Footer>
    </>
  );
};
