import handingemail from "../../icons/handingemail.svg";
import handingok from "../../icons/handingok.svg";

const Sent = () => {
  return (
    <div className="logInBox">
      <h1 className="headerText">Reset Complete!</h1>
      <a className="subtitle">
        We’ve sent you an email with instrtuctions to reset your password.
      </a>
      <div className="handingimg">
        <img
          src={handingemail}
          style={{ marginTop: 20 }}
          alt={"no handing email"}
        />
        <img
          src={handingok}
          style={{ marginLeft: "20%", marginTop: 20 }}
          alt={"no handing ok"}
        />
      </div>
    </div>
  );
};

export default Sent;
