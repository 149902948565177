import searchIcon from "../../../icons/property/search.svg";
import locationIcon from "../../../icons/property/location.svg";
import { createRef, RefObject, useEffect, useState } from "react";
import tempComparison from "../../../icons/property/tempComparison.png";
import { SelectedConfiguration } from "./SelectedConfiguration";

export const Comparison = ({
  mapBounds,
  selectedProperty,
  addressArray,
  setAddressArray,
}) => {
  const [inputAddress, setInputAddress] = useState<string>();
  const [formattedAddress, setFormattedAddress] = useState<string>();

  const refArray = addressArray.map((address) => {
    const newRef = createRef<HTMLDivElement>();
    return newRef;
  });

  const [selectedCard, setSelectedCard] =
    useState<"head" | "tail" | number>("head");
  const [configurationList, setConfigurationList] = useState<number[]>([]);
  const [selectedConfiguration, setSelectedConfiguration] = useState<number>(0);

  const inputRef = createRef<HTMLInputElement>();
  const divBeginner = createRef<HTMLDivElement>();
  const divEnd = createRef<HTMLDivElement>();

  useEffect(() => {
    const currentRef = inputRef.current;
    if (currentRef) {
      const searchBox = new google.maps.places.SearchBox(currentRef);
      searchBox.setBounds(mapBounds);
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        if (!places?.length && places?.length == 0) {
          return;
        }
        places?.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }
          if (place.formatted_address) {
            setFormattedAddress(place.formatted_address);
            setInputAddress(place.formatted_address);
          }
        });
      });
    }
  }, []);
  useEffect(() => {
    const currentRef = inputRef.current;
    const width = currentRef?.offsetWidth.toString();
    const searchBox = document.getElementsByClassName(
      "pac-container"
    )[1] as HTMLElement;
    if (searchBox) {
      searchBox.classList.add("search-box-2");
    }
  }, [inputRef]);

  useEffect(() => {
    goto(divBeginner);
  }, []);
  useEffect(() => {
    if (formattedAddress) {
      const newRef = createRef<HTMLDivElement>();
      refArray.push(newRef);
      setAddressArray([...addressArray, formattedAddress]);
    }
  }, [formattedAddress]);
  useEffect(() => {
    goto(divEnd);
  }, [addressArray]);
  useEffect(() => {
    const theList = [0, 1, 2, 3, 4];

    setConfigurationList(theList);
  }, [selectedCard]);

  useEffect(() => {
    console.log(configurationList);
  }, [configurationList]);

  const goto = (divBeginner) => {
    const divBeginnerRect = divBeginner.current?.getBoundingClientRect();
    const container = document.getElementById("horizontal");
    if (container && divBeginnerRect) {
      container.scrollLeft += divBeginnerRect?.x - divBeginnerRect.width / 2;
    }
  };

  return (
    <div className="property-body">
      <div style={{ height: "33vh", flexDirection: "row", display: "flex" }}>
        <div className="title-vertical">SELECTED SITES</div>
        <div className="selected-sites" id="horizontal">
          <div className="stick-dots">
            <div
              className={selectedCard === "head" ? "blue-bar" : "grey-dot"}
              onClick={() => {
                goto(divBeginner);
                setSelectedCard("head");
              }}
            />
            {refArray.map((eachRef, index) => {
              return (
                <div
                  className={selectedCard === index ? "blue-bar" : "grey-dot"}
                  onClick={() => {
                    goto(eachRef);
                    setSelectedCard(index);
                  }}
                />
              );
            })}
            <div
              className={selectedCard === "tail" ? "blue-bar" : "grey-dot"}
              onClick={() => {
                goto(divEnd);
                setSelectedCard("tail");
              }}
            />
            <div />
          </div>
          <div
            className="selected-sites-card"
            style={
              selectedCard == "head"
                ? { border: "1px solid #4F7BEA", borderRadius: "5px" }
                : {}
            }
            ref={divBeginner}
            onClick={() => {
              setSelectedCard("head");
            }}
          >
            <div className="search-div">
              <img
                src={tempComparison}
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="search-title">
              {selectedProperty?.address.split(",")[0]}
            </div>
          </div>
          {addressArray.map((address, index) => {
            return (
              <div
                className="selected-sites-card"
                style={
                  selectedCard == index
                    ? { border: "1px solid #4F7BEA", borderRadius: "5px" }
                    : {}
                }
                ref={refArray[index]}
                onClick={() => {
                  setSelectedCard(index);
                  if (index > 0) goto(refArray[index - 1]);
                  else goto(divBeginner);
                }}
              >
                <div
                  className="search-div"
                  style={{ padding: "0px !important" }}
                >
                  <img
                    src={tempComparison}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div className="search-title">{address.split(",")[0]}</div>
              </div>
            );
          })}
          <div
            className="selected-sites-card"
            ref={divEnd}
            onClick={() => {
              setSelectedCard("tail");
            }}
          >
            <div className="search-div">
              <img
                src={locationIcon}
                style={{ cursor: "pointer" }}
                width="20vw"
              />
            </div>
            <div className="search-title">Location</div>
            <div className="search-input">
              <input
                value={inputAddress}
                onChange={(evt) => {
                  setInputAddress(evt.currentTarget.value);
                }}
                ref={inputRef}
                placeholder="address"
              />
              <img src={searchIcon} />
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "60vh", flexDirection: "row", display: "flex" }}>
        <div className="title-vertical">SELECTED CONFIGURATIONS</div>
        <div className="configuration-container" id="horizontal-2">
          {configurationList.map((el, index) => {
            return (
              <SelectedConfiguration
                index={index}
                setSelectedConfiguration={setSelectedConfiguration}
                selectedConfiguration={selectedConfiguration}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
