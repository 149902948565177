import { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { InputBase } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import emailLogo from "../../icons/email.svg";
import passwordLogo from "../../icons/password.svg";
import checkInput from "../../utils/checkInput";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchUserRequest } from "../../redux/actions";
import { useHistory } from "react-router";

const LoginBox = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailValid, setEmailValid] = useState<Boolean>(true);
  const [emailChecking, setEmailChecking] = useState<Boolean>(false);
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user.user);
  const history = useHistory();

  useEffect(() => {
    if (userState && userState.authToken) history.push("/risemap");
  }, [userState]);

  return (
    <div className="logInBox">
      <h1 className="headerText">Welcome Back</h1>
      <a className="subtitle">Enter your credentials to access your account</a>
      <form className="logInForm">
        <InputBase
          id="email"
          placeholder="Enter your email"
          className="form-input"
          startAdornment={
            <InputAdornment position="start">
              <img src={emailLogo} alt={"no email logo"} />
            </InputAdornment>
          }
          value={email}
          onChange={(evt) => {
            setEmail(evt.target.value);
            if (emailChecking) {
              setEmailValid(checkInput(evt.target.value, "email"));
            }
          }}
          onBlur={(evt) => {
            setEmailChecking(!checkInput(evt.target.value, "email"));
            setEmailValid(checkInput(evt.target.value, "email"));
          }}
        />
        {!emailValid && <a className="validatingHint">Invalid email format</a>}
        <InputBase
          id="password"
          placeholder="Enter your password"
          className="form-input"
          type="password"
          startAdornment={
            <InputAdornment position="start">
              <img src={passwordLogo} alt={"no password logo"} />
            </InputAdornment>
          }
          value={password}
          onChange={(evt) => {
            setPassword(evt.target.value);
          }}
        />

        <Button
          className="form-button"
          onClick={() => {
            dispatch(
              fetchUserRequest({
                path: "/login",
                httpMethod: "Post",
                data: {
                  userEmail: email,
                  password: password,
                },
              })
            );
          }}
        >
          Sign in
        </Button>
      </form>
    </div>
  );
};

export default LoginBox;
